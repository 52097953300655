import apiSlice from "./api/api";
import {
  SAVE_BOOKINGS,
  UPCOMING_BOOKINGS,
  BOOKING_HISTORY,
  UPDATE_BOOKING_STATUS,
  UPDATE_DELIVERY_STATUS,
  GET_BOOKING,
  GENERATOR_REPORTS,
  SAVE_COMMENT,
  BOOKING_REQUESTS,
  CONFIRMED_BOOKINGS,
  DELETE_BOOKING
} from "./constants";

const bookingApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // get upcoming bookings
    getBookingRequests: builder.mutation({
      query: (params) => ({
        url: `${BOOKING_REQUESTS}?page=${params.page}`,
        method: "POST",
        body: params
      }),
      providesTags: ["Bookings"],
    }),
    // get upcoming bookings
    getConfirmedBookings: builder.mutation({
      query: (params) => ({
        url: `${CONFIRMED_BOOKINGS}?page=${params.page}`,
        method: "POST",
        body: params
      }),
      providesTags: ["Bookings"],
    }),
    //get booking history
    bookingHistory: builder.mutation({
      query: (params) => ({
        url: `${BOOKING_HISTORY}?page=${params.page}`,
        method: "POST",
        body: params
      }),
      providesTags: ["Bookings"],
    }),
    //get generator history
    generatorReports: builder.mutation({
      query: (params) => ({
        url: GENERATOR_REPORTS,
        method: "POST",
        body: params
      }),
      providesTags: ["Bookings"],
    }),
    //get single booking
    getBooking: builder.query({
      query: (bookingId) => ({
        url: `${GET_BOOKING}/${bookingId}`,
        method: "GET",
      }),
      providesTags: [""],
    }),
    // save bookings
    saveBookings: builder.mutation({
      query: (data) => ({
        url: SAVE_BOOKINGS,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["Bookings"]
    }),
    // update bookings
    updateBookingStatus: builder.mutation({
      query: (data) => ({
        url: `${UPDATE_BOOKING_STATUS}`,
        body: data,
        method: "PATCH",
      }),
      invalidatesTags: ["Bookings"]
    }),
    // update bookings
    updateDeliveryStatus: builder.mutation({
      query: (data) => ({
        url: `${UPDATE_DELIVERY_STATUS}`,
        body: data,
        method: "PATCH",
      }),
      invalidatesTags: ["Bookings"]
    }),
    // save comment
    saveComment: builder.mutation({
      query: (data) => ({
        url: `${SAVE_COMMENT}`,
        method: "POST",
        body: data
      }),
      invalidatesTags: ["Bookings"]
    }),
  }),
});

export const {
  useGetBookingQuery,
  useGetBookingRequestsMutation,
  useGetConfirmedBookingsMutation,
  useGeneratorReportsMutation,
  useBookingHistoryMutation,
  useSaveBookingsMutation,
  useUpdateBookingStatusMutation,
  useUpdateDeliveryStatusMutation,
  useSaveCommentMutation
} = bookingApiSlice;
