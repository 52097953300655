
import { createApi } from "@reduxjs/toolkit/query/react";
import customBaseQuery from "../custom-query/customQuery";

const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: customBaseQuery,
  tagTypes: ["Users", "Bookings", "Customers", "Leads", "Invoices", "Generators", "CustomerBookings"],
  endpoints: (builder) => ({}),
  // refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 50000,
});

export default apiSlice;
