export const eventTypes = [
    {
      value: "Kid Party",
      label: "Kid Party",
    },
    {
      value: "Naming Ceremony",
      label: "Naming Ceremony",
    },
    {
      value: "Birthday Party",
      label: "Birthday Party",
    },
    {
      value: "Wedding",
      label: "Wedding",
    },
    {
      value: "Concert",
      label: "Concert",
    },
    {
      value: "Conference",
      label: "Conference",
    },
    {
      value: "Exhibition",
      label: "Exhibition",
    },
    {
      value: "Coronation",
      label: "Coronation",
    },
    {
      value: "Others",
      label: "Others",
    },
];

export const eventLocations = [
  { value: "", label: "Please Select" },
  { value: "Event Hall", label: "Event Hall" },
  { value: "Telecom Mast", label: "Telecom Mast" },
  { value: "Residence", label: "Residence" },
  { value: "Construction Site", label: "Construction Site" },
  { value: "Outdoor Space", label: "Outdoor Space" },
];

  
export const jobStatus = [
  { value: '', label: "All Status" },
  { value: 'PENDING', label: "Pending" },
  { value: 'PAID', label: "Paid" },
  { value: 'PARTIAL', label: "Partialy Paid" },
  { value: 'CANCELLED', label: "Cancelled" },
  { value: 'NOT_PAID', label: "Not Paid" },
  { value: 'CLOSED', label: "Closed" },
];

export const pad = (num = 0, size) => num.toString().padStart(size, '0');

export const formatNumber = (number) =>
  !number
    ? `₦0`
    : `₦ ${parseFloat(number).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`;