/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import DatePicker from "react-datepicker";
import { Applogo } from "../../../Routes/ImagePath";
import { useSaveBookingMutation } from "../../../services/customer.service";
import PlacesAutocomplete from "../../../components/PlacesAutoComplete/places-autocomplete";
import { getGeocode, getLatLng } from "use-places-autocomplete";
import alertify from "alertifyjs";
import Select from "react-select";

import * as Yup from "yup";
import { eventTypes } from "../../../utils";

const BookingForm = (props) => {
  const {type} = useParams();
  const [saveBooking, { isLoading, isSuccess, isError }] = useSaveBookingMutation();

  const {
    control,
    register,
    reset,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(Yup.object().shape({
      email: Yup.string()
      .email("Must be a valid email")
      .max(255)
      .required("Email is required"),
    company_name: Yup.string().max(255).required("Please provide company name"),
    contact_person: Yup.string().max(255).required("Contact person name is required"),
    startDate: Yup.string().max(255).required("Date of use is required"),
    endDate: Yup.string().max(255).required("End Date of use is required"),
    contact_number: Yup.string().max(255).required("Please provide your WhatsApp number"),
    address: Yup.string()
      .max(300)
      .required("Delivery adress is required"),
    gen_capacity: Yup.string().required('This field is required'),
    running_hours: Yup.string().required('This field is required'),
    })),
    mode: 'onBlur',
  });

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#CBE9EB" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#CBE9EB",
      },
    }),
  };

  const onSubmit = async (formData) => {
    const payload = {...formData};
    payload.form_type = type;
    payload.startDate = dayjs(formData.startDate).format('YYYY-MM-DD HH:mm:ss');
    payload.endDate = dayjs(formData.endDate).format('YYYY-MM-DD HH:mm:ss');

    await saveBooking(payload).then(res => {
      const saveData = res.data
      if (saveData && saveData?.success) {
        alertify.success(res.data.message);
        resetForm()
      }  else {
        alertify.error(saveData?.message);
      }
    });    

    if(isError) {
      alertify.error("Something went wrong. Unable to save user");
    }
  }


  const resetForm = () => {
    reset({
      contact_person: "",
      company_name: "",
      contact_number: "",
      email: "",
      address: "",
      delivery_coords: "",
      startDate: "",
      endDate: "",
      running_hours:"",
      extra_cable: "",
      supply_diesel: "",
      // distribution_box: "",
      co_db: "",
      has_3_phases: "",
      gen_capacity: "",
      items_to_power: ""
    })
  }

  return (
    <div className="account-page">
      <div className="main-wrapper">
        <div className="account-content">
          <div className="container">
            {/* Account Logo */}
            <div className="account-logo">
              <Link to="https://www.premiumpower.org">
                <img src={Applogo} alt="Premium Power" />
              </Link>
            </div>
            {/* /Account Logo */}
            <div className="account-box register">
              <div className="account-wrapper ">
                <h3 className="account-title">PPS Booking Form</h3>
                <p className="account-subtitle">Complete and submit your booking request</p>
                {/* Account Form */}
                <div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-sm-6 col-md-6">
                      <div className="input-block">
                        <label>
                          Full Name <span className="text-danger">*</span>
                        </label>

                        <Controller
                          name="contact_person"
                          control={control}
                          render={({ field }) => (
                            <input
                              className={`form-control ${
                                errors?.contact_person ? "is-invalid" : ""
                              }`}
                              type="text"
                              onChange={field.onChange}
                              value={field.value}
                              autoComplete="true"
                            />
                          )}
                        />
                        <span className="text-danger">
                          {" "}
                          {errors.contact_person?.message}{" "}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6">
                      <div className="input-block">
                        <label>
                          Company Name <span className="text-danger">*</span>
                        </label>

                        <Controller
                          name="company_name"
                          control={control}
                          render={({ field }) => (
                            <input
                              className={`form-control ${
                                errors?.company_name ? "is-invalid" : ""
                              }`}
                              type="text"
                              onChange={field.onChange}
                              value={field.value}
                              autoComplete="true"
                            />
                          )}
                        />

                        <span className="text-danger">
                          {" "}
                          {errors.company_name?.message}{" "}
                        </span>
                      </div>
                    </div>
                    
                    <div className="col-sm-6 col-md-6">
                      <div className="input-block">
                        <label>
                          Contact Number <span className="text-danger">*</span>
                        </label>

                        <Controller
                          name="contact_number"
                          control={control}
                          render={({ field }) => (
                            <input
                              className={`form-control ${
                                errors?.contact_number ? "is-invalid" : ""
                              }`}
                              type="text"
                              onChange={field.onChange}
                              value={field.value}
                              autoComplete="true"
                            />
                          )}
                        />

                        <span className="text-danger">
                          {" "}
                          {errors.contact_number?.message}{" "}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6">
                      <div className="input-block">
                        <label>Email Address <span className="text-danger">*</span></label>
                        <Controller
                          name="email"
                          control={control}
                          render={({ field }) => (
                            <input
                              className={`form-control ${
                                errors?.email ? "is-invalid" : ""
                              }`}
                              type="text"
                              onChange={field.onChange}
                              value={field.value}
                              autoComplete="true"
                            />
                          )}
                        />

                        <span className="text-danger">
                          {" "}
                          {errors.email?.message}{" "}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12">
                      <div className="input-block">
                        <label>Address for delivery <span className="text-danger">*</span></label>
                        <Controller
                          name="address"
                          control={control}
                          render={({ field }) => (
                            <PlacesAutocomplete
                              hasError={Boolean(errors.address)}
                              formValue={field.value}
                              onAddressSelect={(address) => {
                                getGeocode({ address: address }).then((results) => {
                                  const { lat, lng } = getLatLng(results[0]);
                                  setValue('address', address);
                                  setValue('delivery_coords', {lat, lng});
                                });
                              }}
                            />
                          )}
                        />

                        <span className="text-danger">
                          {" "}
                          {errors.address?.message}{" "}
                        </span>
                      </div>
                    </div>
                    
                    <div className="col-sm-6 col-md-6">
                      <div className="input-block">
                        <label>
                          Start Date <span className="text-danger">*</span>
                        </label>

                        <Controller
                          name="startDate"
                          control={control}
                          render={({ field }) => (
                            <DatePicker
                              selected={field.value}
                              onChange={field.onChange}
                              className={`form-control floating datetimepicker ${
                                errors?.startDate ? "is-invalid" : ""
                              }`}
                              type="date"
                              dateFormat="dd-MM-yyyy"
                              minDate={new Date()}
                            />
                          )}
                        />
                        <span className="text-danger">
                          {" "}
                          {errors.startDate?.message}{" "}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6">
                      <div className="input-block">
                          <label>
                            End Date <span className="text-danger">*</span>
                          </label>

                          <Controller
                            name="endDate"
                            control={control}
                            render={({ field }) => (
                              <DatePicker
                                selected={field.value}
                                onChange={field.onChange}
                                className={`form-control floating datetimepicker ${
                                  errors?.endDate ? "is-invalid" : ""
                                }`}
                                type="date"
                                dateFormat="dd-MM-yyyy"
                                minDate={new Date()}
                              />
                            )}
                          />
                          <span className="text-danger">
                            {" "}
                            {errors.endDate?.message}{" "}
                          </span>
                        </div>
                    </div>
                    {type === 'events' &&
                    <>
                      <div className="col-sm-6 col-md-6">
                        <div className="input-block">
                          <label>
                            Time of Event <span className="text-danger">*</span>
                          </label>

                          <Controller
                            name="event_time"
                            control={control}
                            render={({ field }) => (
                              <DatePicker
                                selected={field.value}
                                onChange={field.onChange}
                                className={`form-control floating datetimepicker ${
                                  errors?.startDate ? "is-invalid" : ""
                                }`}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Select Time"
                                dateFormat="hh:mm aa"
                              />
                            )}
                          />
                          <span className="text-danger">
                            {" "}
                            {errors.startDate?.message}{" "}
                          </span>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <div className="input-block">
                          <label>Event Type</label>
                          <Controller
                            name="event_type"
                            control={control}
                            render={({ field }) => (
                              <Select 
                                {...field}
                                placeholder="Select Event Type"
                                options={eventTypes}
                                className="select floating"
                                styles={customStyles}
                                value={eventTypes.find(c => c.value === field.value)}
                                onChange={val => field.onChange(val.value)}
                              />
                            )}
                          />
                          
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6">
                        <div className="input-block">
                          <label>Time of test running </label>
                          <Controller
                            name="test_duration"
                            control={control}
                            render={({ field }) => (
                              <input
                                className={`form-control`}
                                type="text"
                                onChange={field.onChange}
                                value={field.value}
                                autoComplete="true"
                              />
                            )}
                          />

                          <span className="text-mute">
                            {" "}
                            Clients are only allowed to test run for 2 hours. Extra hours will be paid for:
                          </span>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6">
                        <div className="input-block">
                          <div>
                            <label htmlFor="">Do you need change over or distribution box?</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              {...register("co_db")}
                              className="form-check-input"
                              type="radio"
                              id="change_over"
                              value="C/O"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="change_over"
                            >
                              Change Over
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              {...register("co_db")}
                              className="form-check-input"
                              type="radio"
                              id="db"
                              value="DB"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="db"
                            >
                              Distribution Box
                            </label>
                          </div>
                        </div>
                      </div>
                    </>
                    }
                    <div className="col-sm-6 col-md-6">
                      <div className="input-block">
                        <div>
                          <label htmlFor="">Should we supply diesel?</label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            {...register("supply_diesel")}
                            className="form-check-input"
                            type="radio"
                            id="should_supply_yes"
                            value="Yes"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="should_supply_yes"
                          >
                            Yes
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            {...register("supply_diesel")}
                            className="form-check-input"
                            type="radio"
                            id="should_supply_no"
                            value="No"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="should_supply_no"
                          >
                            No
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6">
                      <div className="input-block">
                        <div>
                          <label htmlFor="">Does your location operate on 3 phases:</label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            {...register("has_3_phases")}
                            className="form-check-input"
                            type="radio"
                            id="has_3_phases_yes"
                            value="Yes"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="has_3_phases_yes"
                          >
                            Yes
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            {...register("has_3_phases")}
                            className="form-check-input"
                            type="radio"
                            id="has_3_phases_no"
                            value="No"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="has_3_phases_no"
                          >
                            No
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <div className="input-block">
                        <div>
                          <label htmlFor="">Do you need extra cable?</label>
                        </div>
                        
                          <div>
                          <Controller
                            name="extra_cable"
                            control={control}
                            render={({ field }) => (
                              <input
                                className={`form-control`}
                                type="number"
                                onChange={field.onChange}
                                value={field.value}
                                autoComplete="true"
                                placeholder="Please specify length of extra cable needed"
                              />
                            )}
                          />
                            <span className="text-mute">
                              {" "}
                              Our gen comes with complimentary 5-10m cable extra cable comes at an extra cost
                            </span>
                          </div>
                        
                      </div>
                    </div>
                    
                    <div className="col-sm-6 col-md-4">
                      <div className="input-block">
                        <div>
                          <label htmlFor="">Running Hours </label>
                        </div>
                      
                        <div>
                          <Controller
                            name="running_hours"
                            control={control}
                            render={({ field }) => (
                              <input
                                className={`form-control ${
                                  errors?.running_hours ? "is-invalid" : ""
                                }`}
                                type="number"
                                onChange={field.onChange}
                                value={field.value}
                                autoComplete="true"
                              />
                            )}
                          />
                        </div>
                        <span className="text-danger">
                          {" "}
                          {errors.running_hours?.message}{" "}
                        </span>
                      
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <div className="input-block">
                        <div>
                          <label htmlFor="">Required Gen Capacity (Kva) <span className="text-danger">*</span></label>
                        </div>
                      
                        <div>
                        <Controller
                          name="gen_capacity"
                          control={control}
                          render={({ field }) => (
                            <input
                              className={`form-control ${
                                errors?.gen_capacity ? "is-invalid" : ""
                              }`}
                              type="number"
                              onChange={field.onChange}
                              value={field.value}
                              autoComplete="true"
                            />
                          )}
                        />
                        </div>
                        <span className="text-danger">
                          {" "}
                          {errors.gen_capacity?.message}{" "}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="input-block mb-3">
                        <label className="col-form-label">
                          What do you want the generator to power?
                        </label>
                          <Controller
                          name="items_to_power"
                          control={control}
                          render={({ field }) => (
                            <textarea
                              className="form-control"
                              placeholder="list items here"
                              rows={4}
                              onChange={field.onChange}
                              value={field.value}
                            />
                            )}
                          />
                      </div>
                    </div>
                  </div>
                  <div>
                    <h4>KINDLY NOTE THE FOLLOWING:</h4>
                    {type === 'events' ?
                      <ol>
                          <li> ⁠IF THE TECHNICIAN IS REQUIRED TO STAY ON SITE OVER NIGHT, AN ACCOMMODATION HAS TO BE PROVIDED FOR HIM BY THE CLIENT OTHERWISE HE CAN HAND OVER AND CLOSE FOR THE DAY</li>
                          <li> ⁠⁠IF ANY CHANCE WE ARE STOPPED ALONG THE ROAD FOR GATEPASS OR AREA BOYS (CONTINGENCY) THE CLIENT WILL HAVE TO BEAR RESPONSIBILITY </li>
                          <li>GENERATOR WILL BE PICKED UP IMMEDIATELY AFTER EVENT</li>
                        </ol>
                    :
                        <ol>
                          <li>1 DAY RENTAL PERIOD COVERS FOR 18 HOURS ONLY, GEN WILL BE PICKED UP 18 HOURS AFTER DELIVERY.</li>
                          <li>IF THE TECHNICIAN IS REQUIRED TO STAY ON SITE OVER NIGHT, AN ACCOMMODATION HAS TO BE PROVIDED FOR HIM BY THE CLIENT OTHERWISE HE CAN HAND OVER THE GEN AND CLOSE BY 5PM</li>
                          <li>IF ANY CHANCE WE ARE STOPPED  ALONG THE ROAD FOR GATE PASS OR AREA BOYS SETTLEMENT (CONTINGENCY) THE CLIENT WILL HAVE TO BEAR THE RESPONSIBILITY</li>
                        </ol>
                    }
                      <p>PLEASE READ THE TERMS AND CONDITIONS ON INVOICE FOR MORE INFORMATION</p>
                  </div>
                  <div className="submit-section mb-5">
                    <button
                      className="btn btn-primary submit-btn"
                      aria-label="Close"
                      type="submit"
                    >
                      {isLoading ?
                        <>
                          <span
                            className="spinner-border spinner-border-sm me-1"
                            role="status"
                            aria-hidden="true"
                          />Submitting...
                        </>
                        :
                          'Submit' 
                        }
                    </button>
                  </div>
                </form>
                </div>
                {/* /Account Form */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingForm;
