import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { formatNumber, pad } from "../../../../../utils";
import alertify from "alertifyjs";
import { useUpdateInvoiceMutation } from "../../../../../services/invoice.service";
import environmentConfig from "../../../../../_configs/environment.config";

const InvoiceAddEdit = ({invoice}) => {
  const [saveInvoice, { data: invoiceData, isLoading, isSuccess, isError }] = useUpdateInvoiceMutation();

  const [formState, setFormState] = useState({
    total: 0,
    amount: 0,
    discount: 0,
    invoice_id: '',
    vat: 0,
    id: '',
    other_info: '',
    items: [
    {
      description: "",
      quantity: "",
      discount: "",
      unit_cost: "",
      amount: "",
      taxable: false
    },
  ]});

  useEffect(() => {
    let total = 0, amount = 0, discount = 0, vat= 0, items = [], id = '', invoice_id;
    if (invoice) {
      invoice_id= invoice.invoice_id
      id = invoice.id;
      invoice.items.forEach(item => {
        items.push({
          amount: item.amount,
          description: item.description,
          unit_cost: item.unitCost,
          discount: item.discount,
          quantity: item.quantity,
          taxable: item.taxable,
        })
      });
      amount = invoice.amount;
      vat = invoice.vat;
      total = invoice.total;
      setFormState({...formState, amount, total, id, invoice_id, vat, discount, items});
    }
  }, [invoice]);

  const addRow = () => {
    const newRow = {
      description: "",
      quantity: 1,
      unit_cost: "",
      discount: "",
      amount: "",
      taxable: false
    };
    setFormState({...formState, items: [...formState.items, newRow]});
  };

  const removeRow = (id) => {
    const updatedRows = formState.items.filter((row) => row.id !== id);
    setFormState({...formState, items: updatedRows});
  };

  const submit = async () => {
    if (formState.total > 0) {
      await saveInvoice(formState).then(res => {
        const saveData = res.data
        if (saveData?.success ) {  
          alertify.success("Saved successfully");
        } else {
          alertify.error(saveData?.message);
        }
      });    

      if(isError) alertify.error('Unable to generate invoice')
    }
  }

  return (
    <div>
      <div className="row">
        <div className="col-sm-12">
          <form>
            <div className="row">
              <div className="col-sm-6 col-md-3">
                <div className="input-block">
                  <label>
                    INV ID <span className="text-danger">*</span>
                  </label>

                  <input 
                    className="form-control" 
                    type="text" 
                    value={formState.invoice_id} 
                    onChange={(e) => setFormState({...formState, invoice_id: e.target.value})}
                  />

                </div>
              </div>
              <div className="col-sm-6 col-md-3">
                <div className="input-block">
                  <label>
                    Client <span className="text-danger">*</span>
                  </label>

                  <input className="form-control" type="text" readOnly value={invoice?.customer?.company_name} />

                </div>
              </div>
              <div className="col-sm-6 col-md-3">
                <div className="input-block">
                  <label>
                    Booking ID <span className="text-danger">*</span>
                  </label>

                  <input className="form-control" type="text" readOnly value={pad(invoice?.booking?.booking_id, 4)} />

                </div>
              </div>
              <div className="col-sm-6 col-md-3">
                <div className="input-block">
                  <label>Email</label>
                  <input className="form-control" type="email" readOnly value={invoice?.customer?.email} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div className="table-responsive">
                  <table
                    className="table table-hover table-white"
                    id="addTable"
                  >
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Taxable</th>
                        <th className="col-md-6">Description</th>
                        <th>Unit Cost</th>
                        <th>Qty</th>
                        <th>Discount(%)</th>
                        <th>Amount</th>
                        <th> </th>
                      </tr>
                    </thead>
                    <tbody className="tbodyone">
                      {formState.items.map((row, i) => (
                        <tr key={`invoice-item-${i}`}>
                          <td>{i + 1}</td>
                          <td className="text-center">
                            <label className="custom_check">
                              <input 
                                type="checkbox" 
                                // value={row.taxable} 
                                checked={row.taxable}
                                onChange={(e) => {
                                  const items = [...formState.items];
                                  items[i].taxable = e.target.checked;
                                  let total = 0, amount = 0, vat = 0;

                                  if (e.target.checked) {
                                    console.log('calculating tax', environmentConfig.tax );

                                    if (items[i].amount > 0){
                                      vat = items[i].amount * environmentConfig.tax / 100;

                                      items.forEach(item => {
                                        amount += item.amount;
                                        console.log(item.taxable, 'item taxable')
                                        if (item.taxable) {
                                          vat += item.amount * environmentConfig.tax / 100;
                                        }
                                      });
                                      
                                      total = vat + formState.total;
                                    }

                                  } else {
                                    items.forEach(item => {
                                      amount += item.amount
                                      if (item.taxable) {
                                        vat += item.amount * environmentConfig.tax / 100;
                                      }
                                    });
                                  }

                                  
                                  setFormState({...formState, total, vat, amount});
                                }} 
                              />
                              <span className="checkmark" />
                            </label>
                          </td>
                          <td>
                            <input
                              className="form-control"
                              type="text"
                              value={row.description}
                              onChange={(e) => {
                                const newForm = {...formState};
                                newForm.items[i].description = e.target.value;

                                setFormState(newForm);
                              }}
                            />
                          </td>
                          <td>
                            <input
                              className="form-control"
                              type="text"
                              value={row.unit_cost}
                              onChange={(e) => {
                                let total = 0, amount = 0, vat = 0;
                                const items = [...formState.items];

                                if (e.target.value !== '') {
                                  items[i].unit_cost = e.target.value;
                                  items[i].amount = items[i].quantity * e.target.value;                            
                                } else {
                                  items[i].unit_cost = e.target.value;
                                  items[i].amount = 0;
                                }

                                items.forEach(item => {
                                  amount += item.amount
                                  if (item.taxable) {
                                    vat += item.amount * environmentConfig.tax / 100;
                                  }
                                });
                                
                                total = amount + vat;
        
                                setFormState({...formState, amount, total, vat, items});
                              }}
                            />
                          </td>
                          <td>
                            <input
                              className="form-control"
                              type="text"
                              value={row.quantity}
                              onChange={(e) => {
                                let total = 0, amount = 0, vat = 0;
                                const items = [...formState.items];

                                if (e.target.value !== '') {
                                  items[i].quantity = e.target.value;
                                  items[i].amount = items[i].unit_cost * e.target.value;                            
                                } else {
                                  items[i].quantity = e.target.value;
                                  items[i].amount = 0;
                                }
                                items.forEach(item => {
                                  amount += item.amount
                                  if (item.taxable) {
                                    vat += item.amount * environmentConfig.tax / 100;
                                  }
                                });

                                total = amount + vat;
                                
                                setFormState({...formState, amount, total, vat, items});
                              }}
                            />
                          </td>
                          <td>
                            <input
                              className="form-control"
                              type="text"
                              value={row.discount}
                              onChange={(e) => {
                                let total = 0, amount = 0, vat = 0;
                                const items = [...formState.items];

                                if (e.target.value !== '') {
                                  const discount = parseFloat(e.target.value) / 100;
                                  items[i].discount = e.target.value;
                                  const total = items[i].quantity * items[i].unit_cost
                                  const discountAmount = total * discount;  
                                  items[i].amount = total - discountAmount;                          
                                } else {
                                  items[i].discount = e.target.value;
                                  items[i].amount = items[i].quantity * items[i].unit_cost;
                                }
                                items.forEach(item => {
                                  amount += item.amount
                                  if (item.taxable) {
                                    vat += item.amount * environmentConfig.tax / 100;
                                  }
                                });
                                total = amount + vat;

                                setFormState({...formState, amount, total, vat, items});
                              }}
                            />
                          </td>
                          <td>
                            <input
                              className="form-control"
                              type="text"
                              value={row.amount}
                              
                              readOnly
                            />
                          </td>
                          <td>
                            {i === 0 ? 
                              <Link
                              to="#"
                              className="text-success font-18"
                              title="Add"
                              id="addProduct"
                              onClick={addRow}
                            >
                              <i className="fa-solid fa-plus" />
                            </Link>
                            :
                              <Link
                                className="text-danger font-18 remove"
                                title="Remove"
                                onClick={() => removeRow(row.id)}
                              >
                                <i className="fa-regular fa-trash-can" />
                              </Link>
                            }
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="table-responsive">
                  <table className="table table-hover table-white">
                    <tbody>
                      <tr>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td className="text-end">Total</td>
                        <td className="text-end pe-4">{formatNumber(formState.amount)}</td>
                      </tr>
                      <tr>
                        <td colSpan={5} className="text-end">
                          Tax
                        </td>
                        <td className="text-end pe-4">
                          <input
                            className="form-control text-end"
                            type="number"
                            value={formState.vat}
                            onChange={(e) => {
                              const vat = e.target.value;
                              let total = 0
                              if (vat != '') {
                                total = formState.amount + parseFloat(vat)
                              } else {
                                total = formState.amount
                              }
                              setFormState({...formState, vat, total});
                            }}
                          />
                        </td>
                      </tr>
                      {/* <tr>
                        <td colSpan={5} className="text-end">
                          Discount %
                        </td>
                        <td className="text-end pe-4">
                          <input
                            className="form-control text-end"
                            type="text"
                          />
                        </td>
                      </tr> */}
                      <tr>
                        <td colSpan={5} className="text-end pe-4">
                          <b>Grand Total</b>
                        </td>
                        <td className="text-end tdata-width pe-4">
                          <b>{formatNumber(formState.total)}</b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Other Information
                      </label>
                      <textarea
                        className="form-control"
                        rows={4}
                        value={formState.other_info}
                        onChange={(e)=> setFormState({...formState, other_info: e.target.value})}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/*
               */}
            </div>

            <div className="submit-section">
              <Link 
                to="/invoices"
                className="btn btn-default m-r-10"
                
              >
                Cancel
              </Link>
              <button
                className="btn btn-primary submit-btn"
                onClick={submit}
                disabled={isLoading}
                type="reset"
              >
                {isLoading ?
                <>
                  <span
                    className="spinner-border spinner-border-sm me-1"
                    role="status"
                    aria-hidden="true"
                  />Submitting...
                </>
                :
                  'Submit' 
                }
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default InvoiceAddEdit;
