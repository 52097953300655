import React, { useEffect, useState } from "react";

import Breadcrumbs from "../../../../../components/Breadcrumbs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import GeneratorReportTable from "./GeneratorReportTable";
import dayjs from "dayjs";
import { useGeneratorReportsMutation } from "../../../../../services/booking.service";

const GeneratorReport = () => {
  const [getReports, {data, isLoading}] = useGeneratorReportsMutation();

  const [filterData, setFilterData] = useState({
    startDate: dayjs().startOf('M').toDate(),
    endDate: dayjs().toDate()
  })

  useEffect(() => {
    getReports(filterData);
  }, [])

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <Breadcrumbs
          maintitle="Generator Report"
          title="Dashboard"
          subtitle="Reports"
        />
        <div className="row filter-row">
          <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
            <div className="input-block form-focus select-focus">

              <div className="cal-icon focused ">
                <DatePicker
                  className="form-control floating datetimepicker"
                  selected={filterData.startDate}
                  onChange={(date) => setFilterData({...filterData, startDate: date})}
                  dateFormat="dd-MM-yyyy"
                  maxDate={filterData.endDate}
                />
              </div>
              <label className="focus-label">From</label>
            </div>
          </div>
          <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
            <div className="input-block form-focus select-focus">
              <div className="cal-icon">
                <DatePicker
                  className="form-control floating datetimepicker"
                  selected={filterData.endDate}
                  onChange={(date) => setFilterData({...filterData, endDate: date})}                  
                  dateFormat="dd-MM-yyyy"
                  maxDate={new Date()}
                />
              </div>
              <label className="focus-label">To</label>
            </div>
          </div>
          <div className="col-sm-6 col-md-3">
            <Link 
              to="#" 
              onClick={() => getReports(filterData)} 
              className="btn btn-success btn-block w-100">
              {" "}
              Search{" "}
            </Link>
          </div>
        </div>

        <GeneratorReportTable data={data} loading={isLoading} />
      </div>
    </div>
  );
};

export default GeneratorReport;
