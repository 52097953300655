import React from "react";

const ViewGenerators = ({generators, closeGen}) => {
  
  return (
    <>
      <div>
        {/* Add Deduction Modal */}
        <div
          id="generator_details"
          className="modal custom-modal fade"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Generators Requested</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={closeGen}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <table className="table">
                  <thead>
                    <tr>
                    <th>Name</th>
                    <th>Gen Size</th>
                    <th>Capacity Needed</th>
                    </tr>
                  </thead>
                  <tbody>
                    {generators?.map(gen => (
                      <tr key={gen.id}>
                        <td>{gen.generator?.name}</td>
                        <td>{gen.generator?.size}kva</td>
                        <td>{gen?.capacity}kva</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* /Add Deduction Modal */}
      </div>
    </>
  );
};

export default ViewGenerators;
