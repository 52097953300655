import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import Select from "react-select";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import { useSaveLeadMutation } from "../../services/customer.service";

const AddLeadModal = ({ data, onClose }) => {
  const [saveLead, { isLoading, isSuccess, isError }] = useSaveLeadMutation();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Please enter the name'),
    email: Yup.string()
      .email("Please enter a valid email address")
      .required("Email is required"),
    id: Yup.string(),
    phone_no: Yup.string().required('Please provide a phone number')
  });

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onBlur'
  });

  useEffect(() => {
    if(data) {
      reset({
        name: data?.companyName,
        contact_name: data?.contact_name,
        location: data?.location,
        email: data?.email,
        phone_no: data?.phoneNo,
        business_type: data?.businessType,
        id: data?.id
      });
    } else {
      resetForm();
    }
  }, [data]);


  const onSubmit = formData => { 
    saveLead(formData);

    if (isSuccess && !data) {
      resetForm()
    } else {
      document.getElementById('close-form-modal').click();
    }

    if(isError) {

    }
  }

  const resetForm = () => {
    reset({
      name: "",
      contact_name: "",
      email: "",
      phone_no: "",
      location: "",
      business_type: "",
      id: "",
    })
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#CBE9EB" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#CBE9EB",
      },
    }),
  };

  const options = [
    { value: '', label: "Select Business Type" },
    { value: 'RENTALS', label: "Rentals" },
    { value: 'SERVICING', label: "Servicing" },
  ];


  return (
    <div id="lead_form" className="modal custom-modal fade" role="dialog">
      <div
        className="modal-dialog modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{data ? 'Edit' : 'Add'} Lead</h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                resetForm();
                onClose()
              }}
              data-bs-dismiss="modal"
              aria-label="Close"
              id="close-form-modal"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-sm-12">
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      Name <span className="text-danger">*</span>
                    </label>
                    <Controller
                      name="name"
                      control={control}
                      render={({ field }) => (
                        <input
                          className={`form-control ${
                            errors?.name ? "error-input" : ""
                          }`}
                          type="text"
                          onChange={field.onChange}
                          value={field.value}
                          autoComplete="true"
                        />
                      )}
                    />

                    <span className="text-danger">
                      {" "}
                      {errors.name?.message}{" "}
                    </span>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      Contact Name
                    </label>
                    <Controller
                      name="contact_name"
                      control={control}
                      render={({ field }) => (
                        <input
                          className={`form-control ${
                            errors?.contact_name ? "error-input" : ""
                          }`}
                          type="text"
                          onChange={field.onChange}
                          value={field.value}
                          autoComplete="true"
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      Email <span className="text-danger">*</span>
                    </label>
                    <Controller
                      name="email"
                      control={control}
                      render={({ field }) => (
                        <input
                          className={`form-control ${
                            errors?.email ? "error-input" : ""
                          }`}
                          type="text"
                          onChange={field.onChange}
                          value={field.value}
                          autoComplete="true"
                        />
                      )}
                    />
                    <span className="text-danger">
                      {" "}
                      {errors.email?.message}{" "}
                    </span>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="input-block mb-3">
                    <label className="col-form-label">Phone  <span className="text-danger">*</span></label>
                    <Controller
                      name="phone_no"
                      control={control}
                      render={({ field }) => (
                        <input
                          className={`form-control ${
                            errors?.phone_no ? "error-input" : ""
                          }`}
                          type="text"
                          onChange={field.onChange}
                          value={field.value}
                          autoComplete="true"
                        />
                      )}
                    />

                    <span className="text-danger">
                      {" "}
                      {errors.phone_no?.message}{" "}
                    </span>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      Location
                    </label>
                    <Controller
                      name="location"
                      control={control}
                      render={({ field }) => (
                        <input
                          className={`form-control`}
                          type="text"
                          onChange={field.onChange}
                          value={field.value}
                          autoComplete="true"
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="input-block mb-3">
                    <label className="col-form-label">Business Type</label>
                    <Controller
                      name="business_type"
                      control={control}
                      render={({ field }) => (
                        <Select 
                          {...field}
                          placeholder="Business Type"
                          options={options}
                          className="select floating"
                          styles={customStyles}
                          value={options.find(c => c.value === field.value)}
                          onChange={val => field.onChange(val.value)}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="submit-section">
                <button
                  className="btn btn-primary submit-btn"
                  // data-bs-dismiss="modal"
                  // aria-label="Close"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ?
                  <>
                    <span
                      className="spinner-border spinner-border-sm me-1"
                      role="status"
                      aria-hidden="true"
                    />Submitting...
                  </>
                  :
                    'Submit' 
                  }
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddLeadModal;
