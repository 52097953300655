import React, { useEffect, useState } from "react";
import { useSaveCustomerMutation } from "../../services/customer.service";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import alertify from "alertifyjs";
import { CustomerSchema } from "../../utils/validations";

export const ClientModelPopup = ({ data, onClose }) => {
  const [save, { isLoading }] = useSaveCustomerMutation();

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CustomerSchema),
    mode: 'onBlur'
  });

  useEffect(() => {
    if(data) {
      reset(data);
    } else {
      resetForm();
    }
  }, [data]);


  const onSubmit = formData => { 
    save(formData).then(res => {
      if (res.data.success) {
        if (!data) {
          resetForm()
        } else {
          document.getElementById('close-form-modal').click();
        }
        alertify.success("Saved successfully");
      } else {
        alertify.error(res.data.message);
      }
    }).catch(err => {
      alertify.error("Something went wrong. Unable to save user");
    } );
  }

  const resetForm = () => {
    reset({
      company_name: "",
      contact_name: "",
      email: "",
      phone_no: "",
      id: "",
    })
  }

  return (
    <>
      <div id="add_client" className="modal custom-modal fade" role="dialog">
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{data ? 'Edit' : 'Add New'} Client</h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => {
                  resetForm();
                  onClose()
                }}
                data-bs-dismiss="modal"
                aria-label="Close"
                id="close-form-modal"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onSubmit)}>                
              <div className="row">
                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Company Name <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="company_name"
                        control={control}
                        render={({ field }) => (
                          <input
                            className={`form-control ${
                              errors?.name ? "is-invalid" : ""
                            }`}
                            type="text"
                            onChange={field.onChange}
                            value={field.value}
                            autoComplete="true"
                          />
                        )}
                      />
                      <span className="text-danger">
                      {" "}
                      {errors.company_name?.message}{" "}
                    </span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Contact Name</label>
                      <Controller
                        name="contact_name"
                        control={control}
                        render={({ field }) => (
                          <input
                            className={`form-control ${
                              errors?.name ? "is-invalid" : ""
                            }`}
                            type="text"
                            onChange={field.onChange}
                            value={field.value}
                            autoComplete="true"
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Phone Number <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="phone_no"
                        control={control}
                        render={({ field }) => (
                          <input
                            className={`form-control ${
                              errors?.name ? "is-invalid" : ""
                            }`}
                            type="text"
                            onChange={field.onChange}
                            value={field.value}
                            autoComplete="true"
                          />
                        )}
                      />
                      <span className="text-danger">
                        {" "}
                        {errors.phone_no?.message}{" "}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Email <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="email"
                        control={control}
                        render={({ field }) => (
                          <input
                            className={`form-control ${
                              errors?.name ? "is-invalid" : ""
                            }`}
                            type="text"
                            onChange={field.onChange}
                            value={field.value}
                            autoComplete="true"
                          />
                        )}
                      />
                      <span className="text-danger">
                        {" "}
                        {errors.email?.message}{" "}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    // data-bs-dismiss="modal"
                    // aria-label="Close"
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ?
                    <>
                      <span
                        className="spinner-border spinner-border-sm me-1"
                        role="status"
                        aria-hidden="true"
                      />Submitting...
                    </>
                    :
                      'Submit' 
                    }
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
