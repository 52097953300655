import React, { useState } from "react";
import { useSaveCommentMutation } from "../../services/booking.service";
import alertify from "alertifyjs";

const CommentBox = ({booking, closeComment}) => {
  const [saveComment, {isLoading}] = useSaveCommentMutation()  
  const [comment, setComment] = useState('');

  const submit = () => {
    if (booking && comment !== '') {
      saveComment({comment, id: booking.id})
        .then(res => {
          if (res.data.success) {
            setComment('');
            closeComment()
            alertify.success(res.data.message);
            document.getElementById('close-comment').click()
          } else {
            alertify.error(res.data.message);
          }
        }).catch(err => {
          alertify.error(err.response.data.message);
        })
    }
  }

  return (
    <>
      <div>
        <div
          id="booking_comment_box"
          className="modal custom-modal fade"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Add Comment</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={closeComment}
                  id="close-comment"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div class="col-md-12">
                  <div class="input-block mb-3">
                    <label class="col-form-label">Comment</label>
                    <textarea 
                      onChange={(e) => setComment(e.target.value)} 
                      placeholder="enter comment..." 
                      class="form-control"
                    ></textarea>
                  </div>
                </div>
                <div className="submit-section">
                  <button 
                    className="btn btn-default m-r-10"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-primary submit-btn"
                    onClick={submit}
                    disabled={isLoading}
                    type="reset"
                  >
                    {isLoading ?
                    <>
                      <span
                        className="spinner-border spinner-border-sm me-1"
                        role="status"
                        aria-hidden="true"
                      />Submitting...
                    </>
                    :
                      'Submit' 
                    }
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Add Deduction Modal */}
      </div>
    </>
  );
};

export default CommentBox;
