import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { logo } from "../../../../../Routes/ImagePath";
import { useGetInvoiceQuery, useLazyEmailInvoiceQuery } from "../../../../../services/invoice.service";
import { useParams } from 'react-router';
import { formatNumber, pad } from "../../../../../utils";
import dayjs from "dayjs";
import alertify from "alertifyjs";

const InvoiceView = () => {
  const params= useParams()

  const invoiceId = params.id;
  const [sendInvoice, {isLoading: isSending, isSuccess, data: sendingData, error}] = useLazyEmailInvoiceQuery();

  const {data, isLoading} = useGetInvoiceQuery({
    invoiceId,
    skip: !invoiceId
  });

  const downloadPDF = (type) => {
    if (type === 'download') {
      const url = `${process.env.REACT_APP_API_URL}/invoices/pdf/${invoiceId}?type=${type}`;
      window.open(url);
    } else{
      sendInvoice(invoiceId);
    }
  }

  useEffect(() => {
    if (sendingData && sendingData.success) {
      alertify.success('Email Sent')
    }

    if (error) {
      alertify.error('Unable to send email', error?.message)
    }
  }, [sendingData, error]);

  console.log(sendingData, error);

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Invoice</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Invoice</li>
                </ul>
              </div>
              <div className="col-auto float-end ms-auto">
                <div className="btn-group btn-group-sm">
                  <button className="btn btn-white" onClick={() => downloadPDF('email')}>
                    {isSending ? 
                      <span
                        className="spinner-border spinner-border-sm me-1"
                        role="status"
                        aria-hidden="true"
                      />
                      : 'Email'
                    }
                  </button>
                  <button className="btn btn-white" onClick={() => downloadPDF('download')}>PDF</button>
                  <button className="btn btn-white">
                    <i className="fa-solid fa-print fa-lg" /> Print
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-6 m-b-20">
                      <img src={logo} className="inv-logo" alt="Logo" />
                      <ul className="list-unstyled">
                        <li>Premium Power Solution</li>
                        <li>9a Tunde Joda Street, Off Whitesand Avenue,</li>
                        <li>Lekki, Lagos</li>
                        <li>TIN: 205298850001</li>
                      </ul>
                    </div>
                    <div className="col-sm-6 m-b-20">
                      <div className="invoice-details">
                        <h3 className="text-uppercase">Invoice #INV-{pad(data?.invoice_id, 4)}</h3>
                        <ul className="list-unstyled">
                          <li>
                            Date: <span>{dayjs(data?.createdAt).format('MMMM DD, YYYY')}</span>
                          </li>
                          <li>
                            Event date: <span>{dayjs(data?.startDate).format('MMMM DD, YYYY')}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 col-lg-7 col-xl-8 m-b-20">
                      <h5>Invoice to:</h5>
                      <ul className="list-unstyled">
                        <li>
                          <h5>
                            <strong>{data?.customer?.company_name}</strong>
                          </h5>
                        </li>
                        {/* <li>
                          <span>Global Technologies</span>
                        </li> */}
                        <li>{data?.booking?.address}</li>
                        {/* <li>Coosada, AL, 36020</li>
                        <li>United States</li> */}
                        <li>{data?.customer?.phone_no}</li>
                        <li>
                          <Link to="#">{data?.customer.email}</Link>
                        </li>
                      </ul>
                    </div>
                    <div className="col-sm-6 col-lg-5 col-xl-4 m-b-20">
                      <span className="text-muted">Payment Details:</span>
                      <ul className="list-unstyled invoice-payment-details">
                        <li>
                          <h5>
                            Total Due: <span className="text-end">{formatNumber(data?.total)}</span>
                          </h5>
                        </li>
                        <li>
                          Account name: <span>E.U Premium Power Solutions Ltd</span>
                        </li>
                        <li>
                          Bank: <span>STANBIC IBTC</span>
                        </li>
                        <li>
                          Acct No: <span>0032332893</span>
                        </li>
                        {/* <li>
                          Address: <span>3 Goodman Street</span>
                        </li>
                        <li>
                          IBAN: <span>KFH37784028476740</span>
                        </li>
                        <li>
                          SWIFT code: <span>BPT4E</span>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-striped table-hover">
                      <thead>
                        <tr>
                          <th>#</th>
                         
                          <th className="d-none d-sm-table-cell">
                            DESCRIPTION
                          </th>
                          <th>UNIT COST</th>
                          <th>QUANTITY</th>
                          <th className="text-end">TOTAL</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.items.map((item, i) => 
                        <tr key={item.id}>
                          <td>{i + 1}</td>
                          <td className="d-none d-sm-table-cell">
                          {item.description}
                          </td>
                          <td>{formatNumber(item?.unitCost)}</td>
                          <td>{item?.quantity}</td>
                          <td className="text-end">{formatNumber(item?.amount)}</td>
                        </tr> 
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div>
                    <div className="row invoice-payment">
                      <div className="col-sm-7"></div>
                      <div className="col-sm-5">
                        <div className="m-b-20">
                          <div className="table-responsive no-border">
                            <table className="table mb-0">
                              <tbody>
                                <tr>
                                  <th>Subtotal:</th>
                                  <td className="text-end">{formatNumber(data?.amount)}</td>
                                </tr>
                                <tr>
                                  <th>
                                    Vat:{" "}
                                    <span className="text-regular">(7.5%)Statutory</span>
                                  </th>
                                  <td className="text-end">{formatNumber(data?.vat)}</td>
                                </tr>
                                <tr>
                                  <th>Total:</th>
                                  <td className="text-end text-primary">
                                    <h5>{formatNumber(data?.total)}</h5>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    {data?.other_info && 
                    <div className="invoice-info">
                      <h5>Other information</h5>
                      <p className="text-muted">
                        {data?.other_info}
                      </p>
                    </div>}
                    <div className="invoice-info">
                    <h5>Terms & Conditions</h5>
                      <p>
                      This order includes a complimentary 10m generator cable. Kindly confirm that this would be sufficient for your needs as additional cables come at a charge and cannot be ordered separately. Note that this invoice is subject to our technicians' confirmation that the load to be connected is within the operational safety parameters of the generator rented. Failure to ensure that the load is within the acceptable range will lead to the generator not being connected.
-There are no refunds for cancelation of orders once payment is made.
-Refunds will only be valid for technical faults caused by PPS.
-If upon investigation the faults are discovered to be caused by the client; due to bad diesel or overloading the generator, etc the refund will not be processed. - Refunds will be processed within 3 working days.
Kindly ensure that the right location and position area is given as change of location or positioning area will come at an extra cost. Also kindly ensure that at least one of your staff is on ground at the site to receive the generator to avoid delays in delivery and positioning. Any delay above 15 minutes will attract an extra charge of N10,000 and an additional N10,000 for every 30 minutes thereafter.
We would love to amend this invoice to suit your needs to the best of our abilities. Kindly contact our services at 08099700004 and 07038531722 for any clarification.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvoiceView;
