import { Table } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { DELETE_USER } from "../../../../../services/constants";
import { useDispatch } from "react-redux";
import { toggleDelete } from "../../../../../redux/slices/ui.slice";

const UserReportTable = ({ data, openModal, isLoading }) => {
  const dispatch = useDispatch();

  const doDelete = (e, item) => {
    // e.currentTarget.closest('tr').remove()
    dispatch(
      toggleDelete({
        open: true,
        title: 'User',
        url: `${DELETE_USER}/${item.id}`,
        ele: e.currentTarget,
        parent: 'tr'
      })
    );
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (text, record) => (
        <div className="table-avatar">
          <Link to="#">
            {text}
          </Link>
        </div>
      ),
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Phone No.",
      dataIndex: "phone",
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.email.length - b.email.length,
    },

    {
      title: "Role",
      dataIndex: "role",
      render: (text) => (
        <span
          className={
            text === "BOOKING_OFFICER"
              ? "badge bg-inverse-success"
              : text === "ADMIN"
              ? "badge bg-inverse-danger"
              : text === 'SUPERVISOR'
              ? "badge bg-inverse-warning"
              : text === 'CUSTOMER' || text === 'TECHNICIAN'
              ? "badge bg-inverse-info"
              : "badge bg-inverse-default"
          }>
          {text}
        </span>
      ),
      sorter: (a, b) => a.role.length - b.role.length,
    },

    {
      title: "Action",
      render: (record) => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <Link
              className="dropdown-item"
              to="#"
              onClick={() => openModal(record)}
            >
              <i className="fa fa-pencil m-r-5" /> Edit
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              onClick={(e) => doDelete(e, record)}
            >
              <i className="fa fa-trash m-r-5" /> Delete
            </Link>
          </div>
        </div>
      ),
    }
  ];

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="table-responsive">
          <Table
            className="table-striped"
            style={{ overflowX: "auto" }}
            columns={columns}
            dataSource={data}
            rowKey={(record) => record.id}
            loading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default UserReportTable;
