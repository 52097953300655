import React, { useState } from "react";
import Breadcrumbs from "../../../../../components/Breadcrumbs";
import UserReportTable from "./UserReportTable";
import { useGetUsersQuery } from "../../../../../services/settings.service";
import AddUserModal from "../../../../../components/Administration/Settings/AddUseModal";
import DeleteModal from "../../../../../components/modelpopup/deletePopup";

const UserReport = () => {
  const [user, setUser] = useState(null);
  const {data, isLoading} = useGetUsersQuery();

  const editUser = u => {
    setUser(u);
    document.getElementById('add-btn').click();
  }

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <Breadcrumbs
          maintitle="Manage Users"
          title="Dashboard"
          subtitle="Users"
          modal="#add_user"
          name="Add User"
        />

        {/* <div className="row filter-row">
          <div className="col-sm-6 col-md-3">
            <div className="input-block mb-3 form-focus select-focus">
              <Select
                placeholder="Name 1"
                onChange={setSelectedOption}
                options={options}
                styles={customStyles}
              />
              <label className="focus-label">Project Name</label>
            </div>
          </div>

          <div className="col-sm-6 col-md-3">
            <Link to="#" className="btn btn-success w-100">
              Search
            </Link>
          </div>
        </div> */}
        <AddUserModal data={user} onClose={() => setUser(null)} />

        <UserReportTable data={data?.data} openModal={editUser} isLoading={isLoading} />

      </div>
    </div>
  );
};

export default UserReport;
