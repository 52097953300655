import React from "react";
import { Link } from "react-router-dom";
import {
  Avatar_02,
  Avatar_05,
  Avatar_09,
  Avatar_10,
  Avatar_11,
} from "../../Routes/ImagePath";
import Select from "react-select";
import dayjs from "dayjs";

const TicketModelPopup = ({data}) => {
  const company = [
    { value: 1, label: "-" },
    { value: 2, label: "Delta Infotech" },
    { value: 3, label: "International Software Inc" },
  ];

  const staff = [
    { value: 1, label: "-" },
    { value: 2, label: "John Smith" },
    { value: 3, label: "Mike Litorus" },
  ];
  const status = [
    { value: 1, label: "High" },
    { value: 2, label: "Low" },
    { value: 3, label: "Medium" },
  ];
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#CBE9EB" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#CBE9EB",
      },
    }),
  };

  return (
    <>
      <div id="view_customer_booking" className="modal custom-modal fade" role="dialog">
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">View Booking Details</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Contact Person</label>
                      <span className="form-control">{data?.contact_person}</span>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Company Name</label>
                      <span className="form-control">{data?.company_name}</span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Contact Number</label>
                      <span className="form-control">{data?.contact_number}</span>
                      
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Email</label>
                      <span className="form-control">{data?.email}</span>

                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Event Location</label>
                      <span className="form-control">{data?.address}</span>
                    </div>
                  </div>
                  
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                    <label className="col-form-label">Event Date(s)</label>
                    <span className="form-control">
                    {dayjs(data?.startDate).format('DD MMM YYYY')}
                    {" "}
                    {data?.startDate !== data?.endDate && <>
                    - {dayjs(data?.endDate).format('DD MMM YYYY')}</>}
                    </span>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                    <label className="col-form-label">Running Hours</label>
                      <span className="form-control">{data?.running_hours} hours</span>

                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-3">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Gen Capacity</label>
                      <span className="form-control">{data?.gen_capacity}kva</span>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Supply Diesel</label>
                      <span className="form-control">{data?.supply_diesel}</span>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Extra Cable</label>
                      <span className="form-control">{data?.extra_cable}</span>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="input-block mb-3">
                      <label className="col-form-label">3 phases</label>
                      <span className="form-control">{data?.has_3_phases}</span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Items to power</label>
                      <textarea className="form-control" defaultValue={data?.items_to_power} />
                    </div>
                    
                  </div>
                </div>
                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    type="reset"
                  >
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      
    </>
  );
};

export default TicketModelPopup;
