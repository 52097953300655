import { Table } from "antd";
import { Link } from "react-router-dom";
import { useGetRecentCustomersQuery } from "../../../../../services/dashbaord.service";
import { Avatar_06 } from "../../../../../Routes/ImagePath";

const ClientTable = () => {
  const {data} = useGetRecentCustomersQuery();

  const columns = [
    {
      title: "Name",
      dataIndex: "company_name",
      render: (text, record) => (
        <span className="table-avatar">
          <Link to="/profile" className="avatar">
            <img alt="" src={Avatar_06} />
          </Link>
          <Link to="/profile">{text}</Link>
        </span>
      ),
      sorter: (a, b) => a.company_name.length - b.company_name.length,
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.email.length - b.email.length,
    },
    {
      title: "Phone No.",
      dataIndex: "phone_no",
      sorter: (a, b) => a.phone_no.length - b.phone_no.length,
    },
    // {
    //   title: "Action",
    //   sorter: true,
    //   render: () => (
    //     <div className="dropdown dropdown-action text-end">
    //       <Link
    //         to="#"
    //         className="action-icon dropdown-toggle"
    //         data-bs-toggle="dropdown"
    //         aria-expanded="false"
    //       >
    //         <i className="material-icons">more_vert</i>
    //       </Link>
    //       <div className="dropdown-menu dropdown-menu-right">
    //         <Link className="dropdown-item" to="#" data-bs-target="#edit_type">
    //           <i className="fa fa-pencil m-r-5" /> Edit
    //         </Link>
    //         <Link className="dropdown-item" to="#" data-bs-target="#delete">
    //           <i className="fa fa-trash m-r-5" /> Delete
    //         </Link>
    //       </div>
    //     </div>
    //   ),
    // },
  ];
  return (
    <div className="col-md-6 d-flex">
      <div className="card card-table flex-fill">
        <div className="card-header">
          <h3 className="card-title mb-0">Clients</h3>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <Table
              dataSource={data}
              columns={columns}
              pagination={false}
              rowKey={(record) => record.id}
            />
          </div>
        </div>
        <div className="card-footer">
          <Link to="/clients-list">View all clients</Link>
        </div>
      </div>
    </div>
  );
};

export default ClientTable;
