import apiSlice from "./api/api";
import {
  SETTINGS_GENERATORS,
  SETTINGS_USERS,
} from "./constants";

const settingApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // get users
    getUsers: builder.query({
      query: () => ({
        url: SETTINGS_USERS,
        method: "GET",
      }),
      providesTags: ["Users"],
    }),
    // save user
    saveUser: builder.mutation({
      query: (data) => ({
        url: SETTINGS_USERS,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["Users"]
    }),
    // reset password
    updateUser: builder.mutation({
      query: (data) => ({
        url: `${SETTINGS_USERS}/${data.id}`,
        body: data,
        method: "PATCH",
      }),
      invalidatesTags: ["Users"]
    }),
    // delete user
    deleteUser: builder.mutation({
      query: (id) => ({
        url: `${SETTINGS_USERS}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Users"]
    }),
    // get generators
    getGenerators: builder.query({
      query: () => ({
        url: SETTINGS_GENERATORS,
        method: "GET",
      }),
      providesTags: ["Generators"],
    }),
    // save user
    saveGen: builder.mutation({
      query: (data) => ({
        url: SETTINGS_GENERATORS,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["Generators"]
    }),
    // update gen status
    updateGenStatus: builder.mutation({
      query: (id) => ({
        url: `${SETTINGS_GENERATORS}/${id}`,
        body: {},
        method: "PATCH",
      }),
      invalidatesTags: ["Generators"]
    }),
    // delete user
    deleteItem: builder.mutation({
      query: (url) => ({
        url,
        method: "DELETE",
      }),
      invalidatesTags: ["Generators", "Users", "Bookings", "CustomerBookings", "Customers", "Invoices", "Leads"]
    }),
  }),
});

export const {
  useGetUsersQuery,
  useSaveUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useGetGeneratorsQuery,
  useSaveGenMutation,
  useUpdateGenStatusMutation,
  useDeleteItemMutation
} = settingApiSlice;
