import { createSlice } from "@reduxjs/toolkit";


export const uiSlice = createSlice({
  name: "user",
  initialState: { 
    deleteModal: {
      open: false,
      url: null,
      title: null,
      item: null
    },
    alert: {type: null, show: false, message: ''},
    settings: null,
  },
  reducers: {
    toggleDelete: (state, { payload }) => {
      const newState = { ...state };
      newState.deleteModal = payload;
      return newState;
    },
  },
});

export const { toggleDelete } = uiSlice.actions;
export default uiSlice.reducer;
