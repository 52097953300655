/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  Applogo,
  Avatar_02,
  headerlogo,
} from "../../Routes/ImagePath";

import { FaRegBell } from "react-icons/fa";
import { useLocation } from "react-router-dom/dist";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../redux/slices/user.slice";
import { useGetNotificationsQuery, useReadAllMutation, useUpdateReadStatusMutation } from "../../services/auth.service";
import dayjs from "dayjs";
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime)


const Header = (props) => {

  const [notification, setNotifications] = useState(false);
  
  const [profile, setProfile] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {data} = useGetNotificationsQuery();
  const [updateRead] = useUpdateReadStatusMutation();
  const [readAll ] = useReadAllMutation();

  const handlesidebar = () => {
    document.body.classList.toggle("mini-sidebar");
  };

  const onMenuClik = () => {
    document.body.classList.toggle("slide-nav");
  };

  const handleNotification = (noti) => {
    setNotifications(!notification);
    setProfile(false);
  };

  const viewNotification = (noti) => {
    updateRead(noti.id);
    // if (noti.model_type === 'Booking') {
    //   navigate(`/booking/${noti.model_id}`)
    // } else if (noti.model_type === 'CustomerBooking') {
    //   navigate(`/customer-bookings?id=${noti.model_id}`)
    // }

    setNotifications(!notification);
  }

  const handleProfile = () => {
    setProfile(!profile);
    setNotifications(false);
  };

  const location = useLocation();
  let pathname = location.pathname;
  const { user } = useSelector((state) => state.user);

  const logout = () => dispatch(logoutUser())

  return (
    <div className="header" style={{ right: "0px" }}>
      {/* Logo */}
      <div className="header-left">
        <Link to="/dashboard" className="logo">
          <img src={headerlogo} width={40} height={40} alt="img" />
        </Link>
        <Link to="/dashboard" className="logo2">
          <img src={Applogo} width={40} height={40} alt="img" />
        </Link>
      </div>
      {/* /Logo */}
      <Link
        id="toggle_btn"
        to="#"
        style={{
          display: pathname.includes("tasks")
            ? "none"
            : pathname.includes("compose")
            ? "none"
            : "",
        }}
        onClick={handlesidebar}
      >
        <span className="bar-icon">
          <span />
          <span />
          <span />
        </span>
      </Link>
      {/* Header Title */}
      <div className="page-title-box">
        <h3>PPS Admin App</h3>
      </div>
      {/* /Header Title */}
      <Link
        id="mobile_btn"
        className="mobile_btn"
        to="#"
        onClick={() => onMenuClik()}
      >
        <i className="fa fa-bars" />
      </Link>
      {/* Header Menu */}
      <ul className="nav user-menu">
        
        {/* Notifications */}
        <li className="nav-item dropdown">
          <Link
            to="#"
            className="dropdown-toggle nav-link"
            data-bs-toggle="dropdown"
            onClick={handleNotification}
          >
            <i>
              <FaRegBell />
            </i>{" "}
            {data?.length > 0 && <span className="badge badge-pill">{data?.length}</span>}
          </Link>
          <div
            className={`dropdown-menu dropdown-menu-end notifications ${
              notification ? "show" : ""
            }`}
          >
            <div className="topnav-dropdown-header">
              <span className="notification-title">Notifications</span>
              <Link
                to="#"
                onClick={() => {
                  setNotifications(false);
                  readAll()
                }}
                className="clear-noti"
              >
                {" "}
                Clear All{" "}
              </Link>
            </div>
            <div className="noti-content">
              <ul className="notification-list">
                {data?.map((val, index) => {
                  return (
                    <li className="notification-message" key={index}>
                      <Link
                        onClick={() => {
                          localStorage.setItem("minheight", "true");
                          viewNotification(val)
                        }}
                        to={val.model_type === 'Booking' ?
                            `/booking/${val.model_id}`
                          : val.model_type === 'CustomerBooking' ?
                           `/customer-bookings?id=${val.model_id}` : "#"
                        }
                      >
                        <div className="media d-flex">
                          {/* <span className="avatar flex-shrink-0">
                            <img alt="" src={val.image} />
                          </span> */}
                          <div className="media-body">
                            <p className="noti-details">
                              <span className="noti-title">{val.title}</span>{" "}
                              {"by "}
                              <span className="noti-title">
                                {val.from}
                              </span>
                            </p>
                            <p className="noti-time">
                              <span className="notification-time">
                                {dayjs(val.createdAt).fromNow()}
                              </span>
                            </p>
                          </div>
                        </div>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="topnav-dropdown-footer">
              <Link
                onClick={() => localStorage.setItem("minheight", "true")}
                to="/app/administrator/activities"
              >
                View all Notifications
              </Link>
            </div>
          </div>
        </li>
        {/* /Notifications */}
        <li className="nav-item dropdown has-arrow main-drop">
          <Link
            to="#"
            className="dropdown-toggle nav-link"
            data-bs-toggle="dropdown"
            onClick={handleProfile}
          >
            {" "}
            <span className="user-img me-1">
              <img src={Avatar_02} alt="img" />
              <span className="status online" />
            </span>
            <span>{user ? `${user?.name}` : "Admin"}</span>
          </Link>
          <div
            className={`dropdown-menu dropdown-menu-end ${
              profile ? "show" : ""
            }`}
          >
            {/* <Link className="dropdown-item" to="/profile">
              My Profile
            </Link> */}
            {/* <Link className="dropdown-item" to="/settings/companysetting">
              Settings
            </Link> */}
            <Link className="dropdown-item" onClick={logout} to="/">
              Logout
            </Link>
          </div>
        </li>
      </ul>
      {/* /Header Menu */}
      {/* Mobile Menu */}
      <div className="dropdown mobile-user-menu">
        <Link
          to="#"
          className="nav-link dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i className="fa fa-ellipsis-v" />
        </Link>
        <div className="dropdown-menu dropdown-menu-end dropdown-menu-right">
          {/* <Link className="dropdown-item" to="/profile">
            My Profile
          </Link> */}
          {/* <Link className="dropdown-item" to="/settings/companysetting">
            Settings
          </Link> */}
          <Link className="dropdown-item" to="/login">
            Logout
          </Link>
        </div>
      </div>
      {/* /Mobile Menu */}
    </div>
  );
};

export default Header;
