import { Table } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { useGetRecentPaymentsQuery } from "../../../../../services/dashbaord.service";
import { pad } from "../../../../../utils";

const PaymentTable = () => {
  const {data} = useGetRecentPaymentsQuery()

  const columns = [
    {
      title: "Invoice ID",
      dataIndex: "invoice_id",
      render: (text,r) => <Link to={`/invoice/${r.id}`}>#{pad(r.invoice_id, 4)}</Link>,
      sorter: (a, b) => a.invoice_id.length - b.invoice_id.length,
    },
    {
      title: "Client",
      dataIndex: "client",
      sorter: (a, b) => a.client.length - b.client.length,
      render: (t, r) => r.customer?.company_name
    },
    {
      title: "Payment Type	",
      dataIndex: "paymentType",
      sorter: (a, b) => a.paymentType.length - b.paymentType.length,
    },
    {
      title: "Paid Date",
      dataIndex: "createdAt",
      sorter: (a, b) => a.paidDate.length - b.paidDate.length,
    },
    {
      title: "Paid Amount",
      dataIndex: "amount_paid",
      sorter: (a, b) => a.amount_paid.length - b.amount_paid.length,
    },
  ];
  return (
    <div className="col-md-6 d-flex">
      <div className="card card-table flex-fill">
        <div className="card-header">
          <h3 className="card-title mb-0">Payments</h3>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <Table
              dataSource={data}
              columns={columns}
              pagination={false}
              rowKey={(record) => record.id}
            />
          </div>
        </div>
        <div className="card-footer">
          <Link to="/payments-reports">View all payments</Link>
        </div>
      </div>
    </div>
  );
};

export default PaymentTable;
