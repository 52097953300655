/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */

/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { useTranslation } from "react-i18next";
// import { withRouter } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";

const Sidebar = () => {
  const location = useLocation();
  const pathname = location.pathname.split("/")[1];
  // console.log("pageurl", pathname);

  const MenuMore = () => {
    document.getElementById("more-menu-hidden").classList.toggle("hidden");
  };

  const [isSideMenu, setSideMenu] = useState("");
  const [isSideMenunew, setSideMenuNew] = useState("dashboard");
  const [level2Menu, setLevel2Menu] = useState("");
  const [level3Menu, setLevel3Menu] = useState("");
  const [isSidebarExpanded, setSidebarExpanded] = useState(false);
  const [isMouseOverSidebar, setMouseOverSidebar] = useState(false);

  const toggleSidebar = (value) => {
    setSideMenu(value);
    setSideMenuNew(value);
  };

  const toggleLvelTwo = (value) => {
    setLevel2Menu(value);
  };
  const toggleLevelThree = (value) => {
    setLevel3Menu(value);
  };

  useEffect(() => {
    if (
      isMouseOverSidebar &&
      document.body.classList.contains("mini-sidebar")
    ) {
      document.body.classList.add("expand-menu");
      return;
    }
    document.body.classList.remove("expand-menu");
  }, [isMouseOverSidebar]);

  const handleMouseEnter = () => {
    setMouseOverSidebar(true);
  };

  const handleMouseLeave = () => {
    setMouseOverSidebar(false);
  };
  const { t } = useTranslation();

  return (
    <div
      className={`sidebar ${isSidebarExpanded ? "" : "hidden"}`}
      id="sidebar"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="sidebar-inner slimscroll" style={{ overflow: false }}>
        <div id="sidebar-menu" className="sidebar-menu">
          <nav className="greedys sidebar-horizantal" id="horizantal-sidebar">
            <ul className="list-inline-item list-unstyled links">
              <li className="menu-title">
                <span> {t("main")}</span>
              </li>

              <li className={pathname.includes("dashboard") ? "active" : ""}>
                <Link to="/dashboard">
                  <i className="la la-dashboard" /> <span>{t("Dashboard")}</span>
                </Link>
              </li>

              <li className={pathname.includes("clients") ? "active" : ""}>
                <Link to="/clients-list">
                  <i className="la la-users" /> <span>{t("Clients")}</span>
                </Link>
              </li>
              
              <li className={pathname.includes("leads") ? "active" : ""}>
                <Link to="/leads">
                  <i className="la la-user-secret" /> <span>{t("Leads")}</span>
                </Link>
              </li>
              <li 
                className="submenu"
                onClick={() =>
                  toggleSidebar(isSideMenu == "bookings" ? "" : "bookings")
                }
              >
                <Link 
                  to="#"
                  className={isSideMenu == "bookings" ? "subdrop" : ""}
                  
                >
                  <i className="la la-shopping-cart" /> 
                  <span>{`Bookings`}</span>
                  <span className="menu-arrow" />
                </Link>
                {isSideMenu == "bookings" ? (
                  <ul>
                    <li>
                      <Link
                        className={
                          pathname.includes("new-booking") ? "active" : ""
                        }
                        to="/new-booking"
                      >
                        {t("Add New Booking")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          pathname.includes("new-booking") ? "active" : ""
                        }
                        to="/customer-bookings"
                      >
                        {t("Customer Bookings")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          pathname.includes("booking-requests") ? "active" : ""
                        }
                        to="/booking-requests"
                      >
                        {t("Booking Requests")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          pathname.includes("confirmed-bookings") ? "active" : ""
                        }
                        to="/confirmed-bookings"
                      >
                        {t("Confirmed Bookings")}
                      </Link>
                    </li>
                  </ul>
                ) : (
                  ""
                )}
              </li>
              <li
                className={
                  pathname.includes("invoices")
                    ? "active"
                    : pathname.includes("invoice-view")
                    ? "active"
                    : ""
                }
              >
                <Link to="/invoices">
                  <i className="la la-file-invoice" /> <span>{`Invoices`}</span>
                </Link>
              </li>
              <li 
                className="submenu"
                onClick={() =>
                  toggleSidebar(isSideMenu == "reports" ? "" : "reports")
                }
              >
                <Link
                  to="#"
                  className={isSideMenu == "reports" ? "subdrop" : ""}
                  
                >
                  <i className="la la-pie-chart" />{" "}
                  <span> {t("Reports")} </span>
                  <span className="menu-arrow" />
                </Link>
                {isSideMenu == "reports" ? (
                  <ul>
                      {/* <li>
                        <Link
                          className={
                            pathname.includes("expense-reports") ? "active" : ""
                          }
                          to="/booking-history"
                        >
                          {t("Real time Reports")}
                        </Link>
                      </li> */}
                    <li>
                      <Link
                        className={
                          pathname.includes("expense-reports") ? "active" : ""
                        }
                        to="/booking-history"
                      >
                        {t("Booking History")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          pathname.includes("invoice-reports") ? "active" : ""
                        }
                        to="/generator-reports"
                      >
                        {t("Generator Reports")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          pathname.includes("payments-reports") ? "active" : ""
                        }
                        to="/payments-reports"
                      >
                        {t("Payment Report")}
                      </Link>
                    </li>
                  </ul>
                ) : (
                  ""
                )}
              </li>
              <li 
                className="submenu"
                onClick={() =>
                  toggleSidebar(isSideMenu == "settings" ? "" : "settings")
                }
              >
                <Link
                  to="#"
                  className={isSideMenu == "settings" ? "subdrop" : ""}
                >
                  <i className="la la-cog" />{" "}
                  <span> {t("Settings")} </span>
                  <span className="menu-arrow" />
                </Link>
                {isSideMenu == "settings" ? (
                  <ul>
                    <li>
                      <Link
                        className={
                          pathname.includes("users") ? "active" : ""
                        }
                        to="/settings/users"
                      >
                        User
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          pathname.includes("generators") ? "active" : ""
                        }
                        to="/settings/generators"
                      >
                        {t("Generators")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          pathname.includes("payments-reports") ? "active" : ""
                        }
                        to="/settings/notifications"
                      >
                        {t("Notifications")}
                      </Link>
                    </li>
                  </ul>
                ) : (
                  ""
                )}
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
