import { Table } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { formatNumber, pad } from "../../../../../utils";
import dayjs from "dayjs";

const PaymentReportTable = ({data, loading}) => {

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      sorter: (a, b) => a.id.length - b.id.length,
      render: (t, r, i) => i + 1
    },
    {
      title: "Transaction ID",
      dataIndex: "transaction_id",
      render: (text) => <Link to="#">{text}</Link>,
      sorter: (a, b) => a.transaction_id.length - b.transaction_id.length,
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      sorter: (a, b) => a.createdAt.length - b.createdAt.length,
      render: (t, r) => dayjs(r.createdAt).format('DD MMM YYYY')
    },
    {
      title: "Client Name",
      dataIndex: "clientname",
      sorter: (a, b) => a.clientname.length - b.clientname.length,
      render: (t, r) => 
        <Link to={`/customer/${r.customer.id}`}>{r.customer?.company_name}</Link>
    },

    {
      title: "Payment Method",
      dataIndex: "payment_method",
      sorter: (a, b) => a.payment_method.length - b.payment_method.length,
    },
    {
      title: "Invoice",
      dataIndex: "invoice",
      render: (text,r) => <Link to={`/invoice/${r.invoice.id}`}>#{pad(r.invoice.invoice_id, 4)}</Link>,
      sorter: (a, b) => a.invoice.length - b.invoice.length,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      sorter: (a, b) => a.amount.length - b.amount.length,
      render: (text) => formatNumber(text)
    },
  ];
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="table-responsive">
          <Table
            className="table-striped"
            style={{ overflowX: "auto" }}
            columns={columns}
            dataSource={data}
            loading={loading}
            rowKey={(record) => record.id}
          />
        </div>
      </div>
    </div>
  );
};

export default PaymentReportTable;
