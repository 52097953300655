import apiSlice from "./api/api";
import {
  CUSTOMERS,
  DASHBOARD_SUMMARY,
  LEADS,
  RECENT_BOOKINGS,
  RECENT_CUSTOMERS,
  RECENT_CUSTOMER_BOOKINGS,
  RECENT_INVOICES,
  RECENT_PAYMENTS,
  SALES_SUMMARY,
} from "./constants";

const dashboardApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    //get all invoices
    getRecentInvoices: builder.query({
      query: () => ({
        url: `${RECENT_INVOICES}`,
        method: "GET",
      }),
      providesTags: ["Invoices"],
    }),

    //get all invoices
    getRecentPayments: builder.query({
      query: () => ({
        url: `${RECENT_PAYMENTS}`,
        method: "GET",
      }),
      providesTags: ["Payments"],
    }),
    // get recent custoomers
    getRecentCustomers: builder.query({
      query: () => ({
        url: RECENT_CUSTOMERS,
        method: "GET",
      }),
      providesTags: ["Customers"],
    }),
    getRecentBookings: builder.query({
      query: () => ({
        url: RECENT_BOOKINGS,
        method: "GET",
      }),
      providesTags: ["Bookings"],
    }),
    getSalesSummary: builder.query({
      query: () => ({
        url: SALES_SUMMARY,
        method: "GET",
      }),
      providesTags: [""],
    }),
    getSummary: builder.query({
      query: () => ({
        url: DASHBOARD_SUMMARY,
        method: "GET",
      }),
      providesTags: [""],
    }),
    getRecentCustomerBookings: builder.query({
      query: () => ({
        url: RECENT_CUSTOMER_BOOKINGS,
        method: "GET",
      }),
      providesTags: [""],
    }),
  }),
});

export const {
  useGetRecentInvoicesQuery,
  useGetRecentPaymentsQuery,
  useGetRecentCustomersQuery,
  useGetRecentBookingsQuery,
  useGetSalesSummaryQuery,
  useGetSummaryQuery,
  useGetRecentCustomerBookingsQuery
} = dashboardApiSlice;
