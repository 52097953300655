import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../../../components/Breadcrumbs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { Link } from "react-router-dom";
import InvoiceTable from "./invoiceTable";
import DeleteModal from "../../../../../components/modelpopup/DeleteModal";
import { useGetInvoicesMutation } from "../../../../../services/invoice.service";
import dayjs from "dayjs";

const Invoices = () => {
  const [fetchRecords, {data, isLoading}] = useGetInvoicesMutation();
  const [invoices, setInvoices] = useState([]);

  const [filterData, setFilterData] = useState({
    startDate: dayjs().startOf('M').toDate(),
    endDate: dayjs().toDate(),
    status: '',
  })

  useEffect(() => {
    fetchRecords(filterData);
  }, []);

  useEffect(() => {
    if (data) setInvoices(data);
  }, [data]);

  const options = [
    { value: "Select Status", label: "Select Status" },
    { value: "PENDING", label: "Pending" },
    { value: "PAID", label: "Paid" },
    { value: "PARTIAL", label: "Partially Paid" },
    { value: "NOT_PAID", label: "Not Paid" },
    { value: "CANCELLED", label: "Cancelled" },
    { value: "FREE", label: "Free" },
    { value: "CLOSED", label: "Closed" },
  ];

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#CBE9EB" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#CBE9EB",
      },
    }),
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Invoices"
            title="Dashboard"
            subtitle="Invoices"
            modal="#"
            name="Create Invoice"
            link="/create-invoice"
          />

          <div className="row filter-row">
          <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
            <div className="input-block form-focus select-focus">

              <div className="cal-icon focused ">
                <DatePicker
                  className="form-control floating datetimepicker"
                  selected={filterData.startDate}
                  onChange={(date) => setFilterData({...filterData, startDate: date})}
                  dateFormat="dd-MM-yyyy"
                  maxDate={filterData.startDate}
                />
              </div>
              <label className="focus-label">From</label>
            </div>
          </div>
          <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
            <div className="input-block form-focus select-focus">

              <div className="cal-icon">
                <DatePicker
                  className="form-control floating datetimepicker"
                  selected={filterData.endDate}
                  onChange={(date) => setFilterData({...filterData, endDate: date})}                  
                  dateFormat="dd-MM-yyyy"
                  maxDate={new Date()}
                />
              </div>
              <label className="focus-label">To</label>
            </div>
          </div>
            <div className="col-sm-6 col-md-3">
              <div className="input-block mb-3 form-focus select-focus">
                <Select
                  placeholder="Select Status"
                  onChange={({value}) => setFilterData({...filterData, status: value})}
                  options={options}
                  styles={customStyles}
                />
                <label className="focus-label">Status</label>
              </div>
            </div>
            <div className="col-sm-6 col-md-3">
              <Link to="#" onClick={() => fetchRecords(filterData)} className="btn btn-success w-100">
                Search
              </Link>
            </div>
          </div>

          <InvoiceTable data={invoices} loading={isLoading} />
        </div>
      </div>
    </>
  );
};

export default Invoices;
