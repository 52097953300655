import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";

import { Avatar_01 } from "../../../Routes/ImagePath";

import { useGetBookingQuery } from "../../../services/booking.service";
import { pad } from "../../../utils";
import dayjs from "dayjs";
import BookingReportModal from "../../../components/modelpopup/BookingReportModal";


const BookingDetails = () => {
  const params= useParams()
  const [report, setReport] = useState(null)
  const bookingId = params.id;

  const {data: booking, isLoading} = useGetBookingQuery(bookingId,{
    skip: !bookingId
  });

  return (
    <>
      <div className="page-wrapper">
        <div className="content contain ber-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Booking Details</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Bookings</li>
                </ul>
              </div>
              {/* <div className="col-auto float-end ms-auto">
                <div className="btn-group btn-group-sm">
                  <button className="btn btn-white">CSV</button>
                  <button className="btn btn-white">PDF</button>
                  <button className="btn btn-white">
                    <i className="fa-solid fa-print fa-lg" /> Print
                  </button>
                </div>
              </div> */}
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-6 m-b-20">
                      <h5>Delivery Address</h5>
                      {/* <img src={Applogo} className="inv-logo" alt="Logo" /> */}
                      <ul className="list-unstyled">
                        <li>{booking?.address}</li>
                        
                      </ul>
                    </div>
                    <div className="col-sm-6 m-b-20">
                      <div className="invoice-details">
                        <h3 className="text-uppercase">Booking #PPS-{pad(booking?.booking_id)}</h3>
                        <ul className="list-unstyled">
                          <li>
                            Date Booked: <span>{dayjs(booking?.created_at).format('MMM DD, YYYY')}</span>
                          </li>
                          <li>
                            Event date: <span>{dayjs(booking?.startDate).format('MMM DD, YYYY')}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 col-lg-7 col-xl-8 m-b-20">
                      <h5>Contact Details:</h5>
                      <ul className="list-unstyled">
                        <li>
                          <h5>
                            <strong>{booking?.customer?.contact_name}</strong>
                          </h5>
                        </li>
                        <li>
                          <span>{booking?.customer?.company_name}</span>
                        </li>
                        <li>{booking?.customer?.phone_no}</li>
                        <li>
                          <Link to="#">{booking?.customer?.email}</Link>
                        </li>
                      </ul>
                    </div>
                    <div className="col-sm-6 col-lg-5 col-xl-4 m-b-20">
                      <span className="text-muted">Generator Details:</span>
                      <ul className="list-unstyled invoice-payment-details">
                        {booking?.generators.map(generator => 
                        <li key={generator.id}>
                          <h5>
                            Gen: <span className="text-end">Blue Ivy</span>
                          </h5>
                        </li>
                        )}
                        <li>
                          Extra Cable: <span>{booking?.extra_cable || 'N/A'}</span>
                        </li>
                        <li>
                          DB or C/O: <span> {booking?.distribution_box || 'N/A'} / {booking?.change_over || 'N/A'} </span>
                        </li>
                        <li>
                          Diesel: <span>{booking?.supply_diesel || 'N/A'}</span>
                        </li>
                        <li>
                          {/* Technician: <span>John Doe</span> */}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 col-sm-12 recent-activity">
                      <h3>Booking Activities</h3>
                      <ul className="res-activity-list">
                      {booking?.activities.length > 0 ? (
                        booking?.activities.map((activity, index) => (
                          <li key={index}>
                            <p className="mb-0">{activity.description}</p>
                            <p className="res-activity-time">
                              <i className="fa-regular fa-clock"></i>{" "}
                              {dayjs(activity.created_at).format('hh:mm a')}
                            </p>
                          </li>
                        ))
                      ) : (
                        <p>No activities available.</p>
                      )}
                    </ul>
                    </div>
                    <div className="col-md-4 col-sm-12">
                      <div className="activity">
                        <h3>Comments</h3>
                        <div className="activity-box">
                          <ul className="activity-list">
                            {booking?.comments.map((activity, index) => (
                              <li key={index}>
                                <div className="activity-user">
                                  <Link
                                    to="#"
                                    title={activity.commentor.name}
                                    data-bs-toggle="tooltip"
                                    className="avatar"
                                  >
                                    <img src={Avatar_01} alt={activity.commentor.name} />
                                  </Link>
                                </div>
                                <div className="activity-content">
                                  <div className="timeline-content">
                                    <Link to="/profile" className="name">
                                      {activity.commentor.name}
                                    </Link>{" "}
                                    {activity.comment}{" "}
                                    {/* <Link to="#">{activity.task}</Link> */}
                                    <span className="time">{dayjs(activity.createdAt).format('Dd/MM/YYYY HH:mm')}</span>
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                      <h3>Technician Reports</h3>
                      <div className="experience-box">
                        <ul className="experience-list">
                          {booking?.reports.map((item) => (
                            <li key={item.id}>
                              <div className="experience-user">
                                <div className="before-circle" />
                              </div>
                              <div className="experience-content">
                                <div className="timeline-content">
                                  <Link 
                                    to="#" 
                                    className="name"
                                    data-bs-toggle="modal"
                                    data-bs-target="#report_modal"
                                    onClick={() => setReport(item)}
                                  >
                                    {dayjs.unix(item.start_time / 1000).format('hh:mm a')} - {dayjs.unix(item.end_time / 1000).format('hh:mm a')}
                                  </Link>
                                  <div>{item.reporter.name}</div>
                                  <span className="time">{dayjs(item.created_at).format('DD/MM/YYYY HH:mm')}</span>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BookingReportModal report={report} />
    </>
  );
};

export default BookingDetails;
