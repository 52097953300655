import apiSlice from "./api/api";
import {
  LOGIN,
  GET_USER,
  CHANGE_PASSWORD,
  RESET_PASSWORD,
  GET_NOTIFICATIONS,
  UPDATE_READ_STATUS,
  UPDATE_ALL_STATUS,
} from "./constants";
import { updateUser } from "../redux/slices/user.slice";
import alertify from "alertifyjs";

const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Login user
    login: builder.mutation({
      query: (userData) => ({
        url: LOGIN,
        body: userData,
        method: "POST",
      }),
      onQueryStarted: async (credentials, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          if (data.success) {
            dispatch(
              updateUser({
                token: data.data.token,
                user: data.data,
                isAuthenticated: true,
              })
            );
          } else {
            alertify.error(data.message)
          }
        } catch (error) {
          return;
        }
      },
      transformResponse: (response) => {
        return response;
      },
      invalidatesTags: ["User"],
    }),

    // Change password
    changePassword: builder.mutation({
      query: (data) => ({
        url: CHANGE_PASSWORD,
        body: data,
        method: "POST",
      }),
    }),

    // reset password
    resetPassword: builder.mutation({
      query: (data) => ({
        url: RESET_PASSWORD,
        body: data,
        method: "POST",
      }),
    }),

    // get user details
    getUserDetails: builder.query({
      query: () => ({
        url: GET_USER,
        method: "GET",
      }),
      providesTags: ["User"],
    }),
    // get user notification
    getNotifications: builder.query({
      query: () => ({
        url: GET_NOTIFICATIONS,
        method: "GET",
      }),
      providesTags: ["Notifications"],
    }),
    // get user notification
    updateReadStatus: builder.mutation({
      query: (id) => ({
        url: `${UPDATE_READ_STATUS}/${id}`,
        method: "GET",
      }),
      invalidatesTags: ["Notifications"],
    }),
    readAll: builder.mutation({
      query: (id) => ({
        url: `${UPDATE_ALL_STATUS}`,
        method: "GET",
      }),
      invalidatesTags: ["Notifications"],
    }),
  }),
});

export const {
  useGetUserDetailsQuery,
  useLoginMutation,
  useChangePasswordMutation,
  useResetPasswordMutation,
  useGetNotificationsQuery,
  useUpdateReadStatusMutation,
  useReadAllMutation
} = authApiSlice;
