import { Avatar_01, Avatar_05, Avatar_08, Avatar_13, Avatar_16 } from "../../Routes/ImagePath";

const ActivitiesData = {
    ActivitiesList: [
    {
        "user": "Samuel",
        "avatar": Avatar_01,
        "activity": "Started delivery of generator",
        "timestamp": "4 mins ago"
    },
    {
        "user": "Samuel",
        "avatar": Avatar_16,
        "activity": "Arrived event location",
        "timestamp": "1 hr ago"
    },
    {
        "user": "Joseph",
        "avatar": Avatar_08,
        "activity": "Generator delivered and started",
        "timestamp": "1hr 5 mins ago"
    },
    {
        "user": "Samuel",
        "avatar": Avatar_13,
        "activity": "Sent deisel report",
        "timestamp": "2hrs ago"
    },
    {
        "user": "Samuel",
        "avatar": Avatar_05,
        "activity": "Sent deisel report",
        "timestamp": "2 days ago"
    },

]
}

export default ActivitiesData;