import React from "react";
import Charts from "./charts";
import InvoiceTable from "./invoiceTable";
import PaymentTable from "./paymentTable";
import ClientTable from "./clientTable";
import RecentTable from "./recentTable";
import Breadcrumbs from "../../../../../components/Breadcrumbs";
import { useGetSummaryQuery } from "../../../../../services/dashbaord.service";
import { useNavigate } from "react-router-dom";

const AdminDashboard = () => {
  const {data} = useGetSummaryQuery()

  const navigate = useNavigate();

  return (
    <div className="main-wrapper">
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs maintitle="Welcome Admin!" title="Dashboard" />
          {/* /Page Header */}
          <div className="row">
            {/* {Array.isArray(users) && users.length > 0 ? (
              users.map((item, index) => ( */}
                <div
                  className="col-md-6 col-sm-6 col-lg-6 col-xl-3"
                >
                  <div className="card dash-widget">
                    <div className="card-body" onClick={() => navigate('/settings/generators')}>
                      <span className={`dash-widget-icon fa fa-cubes`} />
                      <div className="dash-widget-info">
                        <h3>{data?.generators || 0}</h3>
                        <span>Generators</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 col-sm-6 col-lg-6 col-xl-3"
                >
                  <div className="card dash-widget">
                    <div className="card-body" onClick={() => navigate('/clients-list')}>
                      <span className={`dash-widget-icon fa fa-users`} />
                      <div className="dash-widget-info">
                      <h3>{data?.customers || 0}</h3>
                        <span>Clients</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 col-sm-6 col-lg-6 col-xl-3"
                >
                  <div className="card dash-widget">
                    <div className="card-body" onClick={() => navigate('/booking-history')}>
                      <span className={`dash-widget-icon la la-file-invoice-dollar`} />
                      <div className="dash-widget-info">
                      <h3>{data?.totalBookings || 0}</h3>
                        <span>Total Bookings</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 col-sm-6 col-lg-6 col-xl-3"
                >
                  <div className="card dash-widget">
                    <div className="card-body" onClick={() => navigate('/booking-requests')}>
                      <span className={`dash-widget-icon la la-file-invoice`} />
                      <div className="dash-widget-info">
                      <h3>{data?.pendingBookings || 0}</h3>
                        <span>Pending Bookings</span>
                      </div>
                    </div>
                  </div>
                </div>
              {/* ))
            ) : (
              <p>No data available</p>
            )} */}
          </div>
          {/* /Charts */}
          <Charts />
          {/* /Charts */}
      
          <div className="row">
            <InvoiceTable />
            <PaymentTable />
          </div>

          <div className="row">
            <ClientTable />
            <RecentTable />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
