import { Table } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { useGetRecentInvoicesQuery } from "../../../../../services/dashbaord.service";
import { formatNumber, pad } from "../../../../../utils";

const InvoiceTable = () => {
  const {data} = useGetRecentInvoicesQuery()


  const columns = [
    {
      title: "Invoice ID",
      dataIndex: "invoice_id",
      render: (text,r) => <Link to={`/invoice/${r.id}`}>#{pad(r.invoice_id, 4)}</Link>,
      sorter: (a, b) => a.invoice_id.length - b.invoice_id.length,
    },
    {
      title: "Booking ID",
      dataIndex: "booking",
      render: (t, r) => (
        <Link
          onClick={() => localStorage.setItem("minheight", "true")}
          to="/booking-details"
        >
          #PPS-{pad(r.booking.booking_id, 4)}
        </Link>
      )
    },

    {
      title: "Client",
      dataIndex: "client",
      sorter: (a, b) => a.client.length - b.client.length,
      render: (t, r) => r.customer?.company_name
    },
    {
      title: "Amount",
      dataIndex: "total",
      render: (text) => <span> {formatNumber(text)}</span>,
      sorter: (a, b) => a.total.length - b.total.length,
    },
    {
      title: "Status",
      dataIndex: "Status",
      render: (text) => (
        <span
          className={
            text === "Paid"
              ? "badge bg-inverse-success"
              : text === "Partially Paid"
              ? "badge bg-inverse-warning"
              : "badge bg-inverse-danger"
          }
        >
          {text}
        </span>
      ),
      sorter: (a, b) => a.Status.length - b.Status.length,
    },
  ];

  return (
    <div className="col-md-6 d-flex">
      <div className="card card-table flex-fill">
        <div className="card-header">
          <h3 className="card-title mb-0">Invoices</h3>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <Table
              dataSource={data}
              columns={columns}
              pagination={false}
              rowKey={(record) => record.id}
            />
          </div>
        </div>
        <div className="card-footer">
          <Link to="/invoices">View all invoices</Link>
        </div>
      </div>
    </div>
  );
};

export default InvoiceTable;
