import React from "react";
import AddEditBooking from "./AddEditBooking";
import Breadcrumbs from "../../../components/Breadcrumbs";

const BookingForm = () => {
  return (
    <>
     
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="New Booking"
            title="Dashboard"
            subtitle="Bookings"
            modal="#"
            name="Go Back"
            link="/bookings"
            icon="arrow-left"
          />
          <AddEditBooking />
        </div>
      </div>
    </>
  );
};

export default BookingForm;
