import React, { useEffect } from "react";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import { useSaveUserMutation, useUpdateUserMutation } from "../../../services/settings.service";
import alertify from "alertifyjs";
import { UserSchema } from "../../../utils/validations";

const AddUserModal = ({ data, onClose }) => {
  const [saveUser, { isLoading, isSuccess, isError }] = useSaveUserMutation();
  const [updateUser, { isLoading: isLoadingUpdate, isSuccess: isSuccessUpdate }] = useUpdateUserMutation();

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(UserSchema),
    mode: 'onBlur'
  });

  useEffect(() => {
    if(data) {
      reset(data);
    } else {
      resetForm();
    }
  }, [data]);


  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#CBE9EB" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#CBE9EB",
      },
    }),
  };

  const options = [
    { value: '', label: "Select Role" },
    { value: 'ADMIN', label: "Admin" },
    { value: 'CLIENT_SERVICE', label: "Client Service" },
    { value: 'SALE_DEPT', label: "Sale Dept" },
    { value: 'ACCOUNTANT', label: "Accountant" },
    { value: 'RENTAL_MANAGER', label: "Rental Manager" },
    { value: 'TECHNICIAN', label: "Technician" },
    { value: 'CUSTOMER', label: "Customer" },
  ];

  const onSubmit = formData => { 
    if (data) {
      updateUser(formData)
    } else {//save new user
      saveUser(formData);
    }
    if (isSuccess) {
      resetForm()
      alertify.success("Saved successfully");
    } else if (isSuccessUpdate) {
      document.getElementById('close-form-modal').click();
      alertify.success("Saved successfully");
    }

    if(isError) {
      alertify.error("Something went wrong. Unable to save user");

    }
  }

  const resetForm = () => {
    reset({
      name: "",
      role: "",
      email: "",
      phone: "",
      password: "",
      confirmPassword: "",
      id: "",
    })
  }


  return (
    <div id="add_user" className="modal custom-modal fade" role="dialog">
      <div
        className="modal-dialog modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{data ? 'Edit' : 'Add New'} User</h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                resetForm();
                onClose()
              }}
              data-bs-dismiss="modal"
              aria-label="Close"
              id="close-form-modal"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-sm-12">
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      Name <span className="text-danger">*</span>
                    </label>
                    <Controller
                      name="name"
                      control={control}
                      render={({ field }) => (
                        <input
                          className={`form-control ${
                            errors?.name ? "is-invalid" : ""
                          }`}
                          type="text"
                          onChange={field.onChange}
                          value={field.value}
                          autoComplete="true"
                        />
                      )}
                    />

                    <span className="text-danger">
                      {" "}
                      {errors.name?.message}{" "}
                    </span>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      Email <span className="text-danger">*</span>
                    </label>
                    <Controller
                      name="email"
                      control={control}
                      render={({ field }) => (
                        <input
                          className={`form-control ${
                            errors?.email ? "is-invalid" : ""
                          }`}
                          type="text"
                          onChange={field.onChange}
                          value={field.value}
                          autoComplete="true"
                        />
                      )}
                    />
                    <span className="text-danger">
                      {" "}
                      {errors.email?.message}{" "}
                    </span>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="input-block mb-3">
                    <label className="col-form-label">Phone  <span className="text-danger">*</span></label>
                    <Controller
                      name="phone"
                      control={control}
                      render={({ field }) => (
                        <input
                          className={`form-control ${
                            errors?.phone ? "is-invalid" : ""
                          }`}
                          type="text"
                          onChange={field.onChange}
                          value={field.value}
                          autoComplete="true"
                        />
                      )}
                    />

                    <span className="text-danger">
                      {" "}
                      {errors.phone?.message}{" "}
                    </span>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="input-block mb-3">
                    <label className="col-form-label">Role <span className="text-danger">*</span></label>
                    <Controller
                      name="role"
                      control={control}
                      render={({ field }) => (
                        <Select 
                          {...field}
                          placeholder="Admin"
                          options={options}
                          className="select floating"
                          styles={customStyles}
                          value={options.find(c => c.value === field.value)}
                          onChange={val => field.onChange(val.value)}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">Password  <span className="text-danger">*</span></label>
                    <Controller
                      name="password"
                      control={control}
                      render={({ field }) => (
                        <input
                          className={`form-control ${
                            errors?.password ? "is-invalid" : ""
                          }`}
                          type={"password"}
                          value={field.value}
                          onChange={field.onChange}
                          // autoComplete="true"
                        />
                      )}
                    />

                    <span className="text-danger">
                      {" "}
                      {errors.password?.message}{" "}
                    </span>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">Confirm Password  <span className="text-danger">*</span></label>
                    <input className="form-control" type="password" />
                  </div>
                </div>
                
              </div>
              {/* <div className="table-responsive m-t-15">
                <table className="table table-striped custom-table">
                  <thead>
                    <tr>
                      <th>Module Permission</th>
                      <th className="text-center">Read</th>
                      <th className="text-center">Write</th>
                      <th className="text-center">Create</th>
                      <th className="text-center">Delete</th>
                      <th className="text-center">Import</th>
                      <th className="text-center">Export</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Employee</td>
                      <td className="text-center">
                        <label className="custom_check">
                          <input type="checkbox" defaultChecked />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td className="text-center">
                        <label className="custom_check">
                          <input type="checkbox" defaultChecked />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td className="text-center">
                        <label className="custom_check">
                          <input type="checkbox" defaultChecked />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td className="text-center">
                        <label className="custom_check">
                          <input type="checkbox" defaultChecked />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td className="text-center">
                        <label className="custom_check">
                          <input type="checkbox" defaultChecked />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td className="text-center">
                        <label className="custom_check">
                          <input type="checkbox" defaultChecked />
                          <span className="checkmark" />
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Holidays</td>
                      <td className="text-center">
                        <label className="custom_check">
                          <input type="checkbox" defaultChecked />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td className="text-center">
                        <label className="custom_check">
                          <input type="checkbox" defaultChecked />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td className="text-center">
                        <label className="custom_check">
                          <input type="checkbox" defaultChecked />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td className="text-center">
                        <label className="custom_check">
                          <input type="checkbox" defaultChecked />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td className="text-center">
                        <label className="custom_check">
                          <input type="checkbox" defaultChecked />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td className="text-center">
                        <label className="custom_check">
                          <input type="checkbox" defaultChecked />
                          <span className="checkmark" />
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Leaves</td>
                      <td className="text-center">
                        <label className="custom_check">
                          <input type="checkbox" defaultChecked />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td className="text-center">
                        <label className="custom_check">
                          <input type="checkbox" defaultChecked />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td className="text-center">
                        <label className="custom_check">
                          <input type="checkbox" defaultChecked />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td className="text-center">
                        <label className="custom_check">
                          <input type="checkbox" defaultChecked />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td className="text-center">
                        <label className="custom_check">
                          <input type="checkbox" defaultChecked />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td className="text-center">
                        <label className="custom_check">
                          <input type="checkbox" defaultChecked />
                          <span className="checkmark" />
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Events</td>
                      <td className="text-center">
                        <label className="custom_check">
                          <input type="checkbox" defaultChecked />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td className="text-center">
                        <label className="custom_check">
                          <input type="checkbox" defaultChecked />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td className="text-center">
                        <label className="custom_check">
                          <input type="checkbox" defaultChecked />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td className="text-center">
                        <label className="custom_check">
                          <input type="checkbox" defaultChecked />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td className="text-center">
                        <label className="custom_check">
                          <input type="checkbox" defaultChecked />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td className="text-center">
                        <label className="custom_check">
                          <input type="checkbox" defaultChecked />
                          <span className="checkmark" />
                        </label>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div> */}
              <div className="submit-section">
                <button
                  className="btn btn-primary submit-btn"
                  // data-bs-dismiss="modal"
                  // aria-label="Close"
                  type="submit"
                  disabled={isLoading || isLoadingUpdate}
                >
                  {isLoading || isLoadingUpdate ?
                  <>
                    <span
                      className="spinner-border spinner-border-sm me-1"
                      role="status"
                      aria-hidden="true"
                    />Submitting...
                  </>
                  :
                    'Submit' 
                  }
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUserModal;
