/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { Avatar_02 } from "../../../Routes/ImagePath";
import { Link, useParams } from "react-router-dom";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { useGetCustomerQuery } from "../../../services/customer.service";
import { pad } from "../../../utils";
import dayjs from "dayjs";
import { Invoices } from "./Invoices";
import { Payments } from "./Payments";
import { Bookings } from "./Bookings";

const Profile = () => {
  const params= useParams()
  const id = params.id;

  const {data} = useGetCustomerQuery(id, {
    skip: !id
  });

  const [customer, setCustomer] = useState(null);

  useEffect(() => {
    if (data) setCustomer(data?.data);
  }, [data]);

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Customer Details"
            title="Dashboard"
            subtitle="Customers"
            modal="#"
            name="Go Back"
            link="/customers"
            icon="arrow-left"
          />
          <div className="card mb-0">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="profile-view">
                    <div className="profile-img-wrap">
                      <div className="profile-img">
                        <Link to="#">
                          <img src={Avatar_02} alt="User Image" />
                        </Link>
                      </div>
                    </div>
                    <div className="profile-basic">
                      <div className="row">
                        <div className="col-md-5">
                          <div className="profile-info-left">
                            <h3 className="user-name m-t-0 mb-0">
                              {customer?.company_name}
                            </h3>
                            <h6 className="text-muted">{customer?.contact_name}</h6>
                            <small className="text-muted">
                              <Link to={`mailto:${customer?.email}`}>
                                  {customer?.email}
                                </Link>
                            </small>
                            <div className="staff-id">
                              Customer ID : {`CLT-${pad(customer?.customer_id, 4)}`}
                            </div>
                            <div className="small doj text-muted">
                              Date Created : {dayjs(customer?.created_at).format('DD MMM YYYY')}
                            </div>
                            <div className="staff-msg">
                              <Link className="btn btn-custom" to="#">
                                Send Message
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-7">
                          <ul className="personal-info">
                            <li>
                              <div className="title">Total Bookings:</div>
                              <div className="text">{customer?.bookings.length}</div>
                            </li>
                            <li>
                              <div className="title">Total Invoices:</div>
                              <div className="text">{customer?.invoices.length}</div>
                            </li>
                            <li>
                              <div className="title">Total Payable:</div>
                              <div className="text">0.00</div>
                            </li>
                            <li>
                              <div className="title">Total Received:</div>
                              <div className="text">0.00</div>
                            </li>
                            <li>
                              <div className="title">Outstanding Balance:</div>
                              <div className="text">0.00</div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="pro-edit">
                      <Link
                        data-bs-target="#profile_info"
                        data-bs-toggle="modal"
                        className="edit-icon"
                        to="#"
                      >
                        <i className="fa-solid fa-pencil"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card tab-box">
            <div className="row user-tabs">
              <div className="col-lg-12 col-md-12 col-sm-12 line-tabs">
                <ul className="nav nav-tabs nav-tabs-bottom">
                  <li className="nav-item">
                    <Link
                      to="#bookings"
                      data-bs-toggle="tab"
                      className="nav-link active"
                    >
                      Bookings
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="#invoices"
                      data-bs-toggle="tab"
                      className="nav-link"
                    >
                      Invoices
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="#payments"
                      data-bs-toggle="tab"
                      className="nav-link"
                    >
                      Payment History
                    </Link>
                  </li>
                  
                </ul>
              </div>
            </div>
          </div>
          {/* Profile Info Tab */}
          <div className="tab-content">
            <div
              id="bookings"
              className="pro-overview tab-pane fade show active"
            >
              <Bookings bookings={customer?.bookings} />
            </div>
            <div className="tab-pane fade" id="invoices">
              <Invoices invoices={customer?.invoices} />
            </div>
            <div className="tab-pane fade" id="payments">
              <Payments payments={customer?.payments} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
