import * as Yup from "yup";

export const UserSchema = Yup.object().shape({
    name: Yup.string().required('Please enter the name'),
    email: Yup.string()
      .email("Please enter a valid email address")
      .required("Email is required"),
    id: Yup.string(),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .max(20, "Password must be at most 20 characters")
      .when('id', (id, schema) => !id ? schema.required() : schema)
});

export const CustomerSchema = Yup.object().shape({
  company_name: Yup.string().required('Please enter the company name'),
  // contact_name: Yup.string().required('Please enter the name'),
  phone_no: Yup.string().required('A phone number is required'),
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("Email is required"),
});

export const GeneratorSchema = Yup.object().shape({
  name: Yup.string().required('Please enter the name'),
  size: Yup.string().required("Size is required"),
    // units: Yup.number().required('Provide available units')
});

export const BookingSchema = Yup.object().shape({
    email: Yup.string()
      .email("Must be a valid email")
      .max(255)
      .required("Email is required"),
    customer_id: Yup.string().max(255).required("Please select a company"),
    startDate: Yup.string().max(255).required("Date of use is required"),
    endDate: Yup.string().max(255).required("End Date of use is required"),
    phone_no: Yup.string().max(255).required("Please provide your WhatsApp number"),
    address: Yup.string()
      .max(300)
      .required("Delivery adress is required"),
    // should_supply: Yup.string(),
    // supply_diesel: Yup.string()
    //                   .transform((val,orig) => orig == "" ? undefined : val)
    //                   .typeError('Diesel is required')
    // .when('should_supply', (should_supply, schema) => {
    //   if (should_supply === 'Yes') { return schema.required('This field is required'); }
    //   return schema;
    // }),
    // hasExtraCable: Yup.string(),
    // extra_cable: Yup.string()
    //                   .transform((val,orig) => orig == "" ? undefined : val)
    //                   .typeError('Extra cable is required')
    // .when('hasExtraCable', (hasExtraCable, schema) => {
    //   if (hasExtraCable === 'Yes') { return schema.required('This field is required'); }
    //   return schema;
    // }),
    // distBox: Yup.string(),
    // distribution_box: Yup.string()
    //                   .transform((val,orig) => orig == "" ? undefined : val)
    //                   .typeError('Enter the number of DB required')
    // .when('distBox', (distBox, schema) => {
    //   if (distBox === 'Yes') { return schema.required('This field is required'); }
    //   return schema;
    // }),
    // hasChangeOver: Yup.string(),
    // change_over: Yup.string()
    //                   .transform((val,orig) => orig == "" ? undefined : val)
    //                   .typeError('Enter the number of C/O required')
    // .when('hasChangeOver', (hasChangeOver, schema) => {
    //   if (hasChangeOver === 'Yes') { return schema.required('This field is required'); }
    //   return schema;
    // }),
    generators: Yup.array().required('You have not selected any generator')
  });