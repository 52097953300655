import { Table } from "antd";
import { formatNumber } from "../../../utils";
import { Link } from "react-router-dom";
import dayjs from "dayjs";

export const Payments = ({payments}) => {

    const columns = [
        {
          title: "#",
          dataIndex: "id",
          sorter: (a, b) => a.id.length - b.id.length,
          render: (t, r, i) => i + 1
        },
        {
          title: "Transaction ID",
          dataIndex: "transaction_id",
          render: (text) => <Link to="#">{text}</Link>,
          sorter: (a, b) => a.transaction_id.length - b.transaction_id.length,
        },
        {
          title: "Date",
          dataIndex: "createdAt",
          sorter: (a, b) => a.createdAt.length - b.createdAt.length,
          render: (t, r) => dayjs(r.createdAt).format('DD MMM YYYY')
        },
    
        {
          title: "Payment Method",
          dataIndex: "payment_method",
          sorter: (a, b) => a.payment_method.length - b.payment_method.length,
        },
        {
          title: "Invoice",
          dataIndex: "invoice",
          render: (text,r) => <Link to={`/invoice/${r.invoiceId}`}>View Invoice</Link>,
          sorter: (a, b) => a.invoice.length - b.invoice.length,
        },
        {
          title: "Amount",
          dataIndex: "amount",
          sorter: (a, b) => a.amount.length - b.amount.length,
          render: (text) => formatNumber(text)
        },
      ];

    return (
        <div className="card-body">
            <div className="table-responsive">
                <Table
                    dataSource={payments}
                    columns={columns}
                    pagination={false}
                    rowKey={(record) => record.id}
                />
            </div>
      </div>
    )
}