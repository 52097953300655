// auth/user
export const LOGIN = "/auth/login";
export const CHANGE_PASSWORD = "/user/change-password";
export const RESET_PASSWORD = "/auth/reset-password";
export const GET_USER = "/auth/details";
export const GET_NOTIFICATIONS = "/notifications/unread";
export const UPDATE_READ_STATUS = "/notifications/read";
export const UPDATE_ALL_STATUS = "/notifications/read-all";

// settings
export const SETTINGS_USERS          = "/settings/users"
export const DELETE_USER             = "/settings/users"
export const SETTINGS_GENERATORS     = "/settings/generators"
export const DELETE_GENERATOR        = "/settings/generators"

// customers
export const CUSTOMERS               = "/customers"
export const CUSTOMERS_ALL           = "/customers/all"
export const LEADS                   = "customers/leads"
export const SAVE_LEAD_COMMENT       = "customers/lead/save-comment"
export const SAVE_CUSTOMER_BOOKING   = "customers/submit-booking"
export const GET_CUSTOMERS_BOOKINGS  = "customers/bookings"
export const GET_CUSTOMER            = "customers/details"
// export const GET_CUSTOMER_BOOKINGS   = "customers/details/"

//bookings
export const SAVE_BOOKINGS          = "/bookings/save"
export const BOOKING_REQUESTS       = "/bookings/requests"
export const CONFIRMED_BOOKINGS     = "/bookings/confirmed"
export const BOOKING_HISTORY        = "/bookings/history"
export const GET_BOOKING            = "/bookings/details"
export const UPDATE_BOOKING_STATUS  = "/bookings/update-status"
export const UPDATE_DELIVERY_STATUS = "/bookings/update-delivery-status"
export const GENERATOR_REPORTS      = "/bookings/generators"
export const SAVE_COMMENT           = "/bookings/save-comment"
export const DELETE_BOOKING         = "/bookings/delete"

// invoices
export const CREATE_INVOICE         = "/invoices/create"
export const GET_INVOICES           = "/invoices/list"
export const GET_INVOICE            = "/invoices/details"
export const SEND_INVOICE           = "/invoices/pdf"
export const UPDATE_INVOICE         = "/invoices/update"
export const UPDATE_INVOICE_STATUS  = "/invoices/update-status"
export const GET_PAYMENTS           = "/invoices/payments"
export const RECORD_PAYMENTS        = "/invoices/record-payments"
export const DELETE_INVOICE         = "/invoices/delete"

// dashboard
export const RECENT_INVOICES        = "/dashboard/invoices"
export const RECENT_PAYMENTS        = "/dashboard/payments"
export const RECENT_BOOKINGS        = "/dashboard/bookings"
export const RECENT_CUSTOMERS       = "/dashboard/customers"
export const SALES_SUMMARY          = "/dashboard/sales-summary"
export const DASHBOARD_SUMMARY      = "/dashboard/summary"
export const RECENT_CUSTOMER_BOOKINGS      = "/dashboard/customer-bookings"
