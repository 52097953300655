import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { useRecordPaymentMutation } from "../../services/invoice.service";
import alertify from "alertifyjs";

const RecordPayment = ({invoice, closeModal}) => {
  const [recordPayment, {data, isLoading, isError}] = useRecordPaymentMutation();
  const closeRef = useRef();

  const [formData, setFormData] = useState({
    invoice_id: "",
    customer: '',
    total: '',
    amount: '',
    status: '',
    payment_method: ''
  });

  useEffect(() => {
    if (invoice) {
      setFormData({
        ...formData,
        invoice_id: invoice.id,
        customer: invoice?.customer?.company_name,
        total: invoice?.total
      })
    }
  }, [invoice]);

  const options = [
    { value: "Select Status", label: "Select Status" },
    { value: "PENDING", label: "Pending" },
    { value: "PAID", label: "Paid" },
    { value: "PARTIAL", label: "Partially Paid" },
    { value: "NOT_PAID", label: "Not Paid" },
    { value: "CANCELLED", label: "Cancelled" },
    { value: "FREE", label: "Free" },
    { value: "CLOSED", label: "Closed" },
  ];

  const options2 = [
    { value: "Select Payment Method", label: "Select Method" },
    { value: "Cash", label: "Cash" },
    { value: "Transfer", label: "Transfer" },
    { value: "Cheque", label: "Cheque" },
    { value: "Online", label: "Online" }
  ];

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#CBE9EB" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#CBE9EB",
      },
    }),
  };

  const submit = async (e) => {
    e.preventDefault();

    if (formData.amount > 0) {
      await recordPayment(formData).then(res => {
        const saveData = res.data
        if (saveData?.success ) {  
          alertify.success("Saved successfully");
          closeModal();
          closeRef.current.click()
        } else {
          alertify.error(saveData?.message);
        }
      });    


      if(isError) alertify.error('Unable to generate invoice')
    }
  }
  return (
    <>
      <div id="record_payment" className="modal custom-modal fade" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Record Payment</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeRef}
                onClick={closeModal}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={submit}>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Customer <span className="text-danger">*</span>
                  </label>
                  <input className="form-control" readOnly defaultValue={formData?.customer} type="text" />
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Invoice Status <span className="text-danger">*</span>
                  </label>
                  <Select
                    placeholder="Select Status"
                    options={options}
                    onChange={({value}) => setFormData({...formData, status: value})}
                    className="select"
                    styles={customStyles}
                  />
                </div>

                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Payment Method <span className="text-danger">*</span>
                  </label>
                  <Select
                    placeholder="Select Payment Method"
                    options={options2}
                    onChange={({value}) => setFormData({...formData, payment_method: value})}
                    className="select"
                    styles={customStyles}
                  />
                </div>
                
                <div className="input-block mb-3">
                  <label className="col-form-label">Total Due</label>
                  <div className="input-group">
                    <span className="input-group-text">₦</span>
                    <input 
                      type="text" 
                      className="form-control" 
                      readOnly
                      defaultValue={formData?.total}
                    />
                    <span className="input-group-text">.00</span>
                  </div>
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">Amount Paid</label>
                  <div className="input-group">
                    <span className="input-group-text">₦</span>
                    <input 
                      type="text" 
                      className="form-control" 
                      onChange={(e) => setFormData({...formData, amount: e.target.value})}
                    />
                    <span className="input-group-text">.00</span>
                  </div>
                </div>
                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ?
                      <>
                        <span
                          className="spinner-border spinner-border-sm me-1"
                          role="status"
                          aria-hidden="true"
                        />Submitting...
                      </>
                      :
                        'Submit' 
                      }
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecordPayment;
