import React from "react";
import { Link } from "react-router-dom";
import { useGetRecentCustomerBookingsQuery } from "../../../../../services/dashbaord.service";
import dayjs from "dayjs";

const RecentTable = () => {
  const {data} = useGetRecentCustomerBookingsQuery();

  return (
    <div className="col-md-6 d-flex">
      <div className="card card-table flex-fill">
        <div className="card-header">
          <h3 className="card-title mb-0">Customer Bookings</h3>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table custom-table mb-0">
              <thead>
                <tr>
                  <th>Booking Details </th>
                  <th>Event Date</th>
                  <th>Gen Cap.</th>
                  {/* <th className="text-end">Action</th> */}
                </tr>
              </thead>
              <tbody>
                {data?.map(row => 
                  <tr key={`customer-booking-${row.id}`}>
                    <td>
                      <h2>
                        <Link to="projects-view">{row.company_name}</Link>
                      </h2>
                      <small className="block text-ellipsis">
                        {row.address}
                      </small>
                    </td>
                    <td>{dayjs(row.startDate).format('DD MMM YYYY')}</td>
                    <td>{row.gen_capacity} Kva </td>
                    {/* <td className="text-end">
                      <div className="dropdown dropdown-action">
                        <Link
                          to="#"
                          className="action-icon dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i className="material-icons">more_vert</i>
                        </Link>
                        <div className="dropdown-menu dropdown-menu-right">
                          <Link className="dropdown-item" to="#">
                            <i className="fa fa-pencil m-r-5" /> Edit
                          </Link>
                          <Link className="dropdown-item" to="#">
                            <i className="fa fa-trash m-r-5" /> Delete
                          </Link>
                        </div>
                      </div>
                    </td> */}
                  </tr>
                )}
                
              </tbody>
            </table>
          </div>
        </div>
        <div className="card-footer">
          <Link to="/customer-bookings">View all bookings</Link>
        </div>
      </div>
    </div>
  );
};

export default RecentTable;
