import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import {getGeocode, getLatLng} from "use-places-autocomplete";

import Select from "react-select";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import { useForm, Controller } from "react-hook-form";
import { useGetGeneratorsQuery } from "../../../services/settings.service";
import { useGetBookingQuery, useSaveBookingsMutation } from "../../../services/booking.service";
import { eventTypes, eventLocations } from "../../../utils";
import alertify from "alertifyjs";
import { BookingSchema } from "../../../utils/validations";
import PlacesAutocomplete from "../../../components/PlacesAutoComplete/places-autocomplete";
import { useGetAllCustomersQuery, useGetCustomerBookingQuery } from "../../../services/customer.service";
import dayjs from "dayjs";


const AddEditBooking = ({}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const bookingId = searchParams.get('edit');
  const customerBookingId = searchParams.get('customer_booking');

  const navigate = useNavigate();
  const [generators, setGenerators] = useState([]);
  const [customers, setCustomers] = useState([]);
  const {data: GenData } = useGetGeneratorsQuery();
  const {data: CustomersData } = useGetAllCustomersQuery();

  const [saveBooking, { isLoading, isSuccess, isError }] = useSaveBookingsMutation();
  
  const {data: bookingData} = useGetBookingQuery(bookingId, {
    skip: !bookingId
  });

  const {data: customerBooking} = useGetCustomerBookingQuery(customerBookingId, {
    skip: !customerBookingId
  });

  useEffect(() => {
    if (GenData) {
      setGenerators(GenData?.data.map((gen) => {
        return {
          value: gen.id, 
          label: gen.name + ' (' + gen.size + ')'
        }
      }))
    }
  }, [GenData]);

  useEffect(() => {
    if (CustomersData) {
      const customerArr = CustomersData?.data.map((item) => {
        return {
          value: item.id, 
          label: item.company_name
        }
      })

      setCustomers([{value: 'New', label: 'New Client'}, ...customerArr]);
    }
  }, [CustomersData]);

  const {
    control,
    reset,
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(BookingSchema),
    mode: 'onBlur'
  });

  
  useEffect(() => {
    if (bookingData) {
      reset({
        customer_id: bookingData?.customer?.id,
        contact_name: bookingData?.contact_person || bookingData?.customer?.contact_name,
        phone_no: bookingData?.contact_phone || bookingData?.customer?.phone_no,
        email: bookingData?.contact_email || bookingData?.customer?.email,
        address: bookingData?.address,
        delivery_coords: bookingData?.delivery_coords,
        startDate: new Date(bookingData?.startDate),
        endDate: new Date(bookingData?.endDate),
        event_location: bookingData?.event_location,
        event_type: bookingData?.event_type,
        hire_period: bookingData?.hire_period,
        running_hours: bookingData?.running_hours,
        add_on: bookingData?.add_on ?? "",
        extra_cable: bookingData?.extra_cable ?? "",
        supply_diesel: bookingData?.supply_diesel ?? "",
        distribution_box: bookingData?.distribution_box ?? "",
        change_over: bookingData?.change_over ?? "",
        id: bookingData?.id,
      })
      if (bookingData?.generators.length) {
        const gens = [];
        for (const generator of bookingData?.generators) {
          gens.push( {
            id: generator.generator.id,
            generator: generator.generator,
            capacity: generator.capacity,
            amount: generator.amount,
            description: generator.description
          })
        }
        setRows(gens);
      }
    }
  }, [bookingData])

  useEffect(() => {
    if (customerBooking) {
      reset({
        customer_id: customerBooking?.data?.customer_id || 'New',
        company_name: customerBooking?.data?.company_name,
        contact_name: customerBooking?.data?.contact_person,
        phone_no: customerBooking?.data?.contact_number,
        email: customerBooking?.data?.email,
        address: customerBooking?.data?.address,
        delivery_coords: customerBooking?.data?.delivery_coords,
        startDate: new Date(customerBooking?.data?.startDate),
        endDate: new Date(customerBooking?.data?.endDate),
        event_location: "",
        event_type: customerBooking?.data?.event_type,
        hire_period: bookingData?.hire_period,
        running_hours: customerBooking?.data?.running_hours,
        extra_cable: customerBooking?.data?.extra_cable ?? "",
        supply_diesel: customerBooking?.data?.supply_diesel ?? "",
        distribution_box: customerBooking?.data?.co_db ?? "",
        change_over: customerBooking?.data?.co_db ?? "",
        customerBookingId
      })
    }
  }, [customerBooking])

 const customerId = watch('customer_id');

  const [rows, setRows] = useState([
    {
      id: "",
      generator: "",
      capacity: "",
      amount: "",
      description: ""
    }
  ]);

  useEffect(() => {
    setValue('generators', rows);
  }, [rows])

  const addRow = () => {
    const newRow = {
      id: "",
      generator: "",
      capacity: "",
      amount: "",
      description: ""
    };
    setRows([...rows, newRow]);
  };

  const removeRow = (idx) => {
    const updatedRows = rows.filter((row, i) => i !== idx);
    setRows(updatedRows);
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#CBE9EB" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#CBE9EB",
      },
    }),
  };

  const onSubmit = async (formData) => {
    const payload = {...formData};
    payload.startDate = dayjs(formData.startDate).format('YYYY-MM-DD HH:mm:ss');
    payload.endDate = dayjs(formData.endDate).format('YYYY-MM-DD HH:mm:ss');

    await saveBooking(payload).then(res => {
      const saveData = res.data
      if (bookingData && saveData?.success) {
        alertify.success("Saved successfully");
        navigate.push('/bookings');
      } else if (saveData?.success) {
        if (!formData.id) resetForm()
        alertify.success("Saved successfully");
      } else {
        alertify.error(saveData?.message);
      }
    });    

    if(isError) {
      alertify.error("Something went wrong. Unable to save user");
    }
  }


  const resetForm = () => {
    reset({
      customer_id: "",
      contact_name: "",
      phone_no: "",
      email: "",
      address: "",
      delivery_coords: "",
      startDate: "",
      endDate: "",
      event_location: "",
      hire_period: "",
      running_hours:"",
      add_on: "",
      extra_cable: "",
      supply_diesel: "",
      should_supply: "",
      distribution_box: "",
      change_over: "",
      id: "",
      generators: []
    })
    setRows([
      {
        id: "",
        generator: "",
        capacity: "",
        amount: "",
        description: ""
      }
    ])
  }

  const setGenerator = (genId, rowIdx) => {
    // find gen
    const generator = GenData.data.find((i) => i.id === genId);
    if (generator) {
      const newArr = [...rows];
      newArr[rowIdx] = {
        id: generator.id,
        generator,
        capacity: generator.size,
        amount: "",
        description: generator.name + ' (' + generator.size + 'kva)'
      }
      setRows(newArr);
    }
  }

  return (
    <div>
      <div className="row">
        <div className="col-sm-12">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-sm-6 col-md-3">
                <div className="input-block">
                  <label>
                    Company <span className="text-danger">*</span>
                  </label>

                  <Controller
                    name="customer_id"
                    control={control}
                    render={({ field }) => (
                      <Select 
                        {...field}
                        placeholder="Select Company"
                        options={customers}
                        className="select floating"
                        styles={customStyles}
                        value={customers.find(c => c.value === field.value)}
                        onChange={(val) => {
                          field.onChange(val.value);
                          const customer = CustomersData?.data.find(cust => cust.id === val.value);
                          if (customer) {
                            setValue('contact_name', customer.contact_name);
                            setValue('phone_no', customer.phone_no);
                            setValue('email', customer.email);
                          }
                        }}
                      />
                    )}
                  />

                  <span className="text-danger">
                    {" "}
                    {errors.customer_id?.message}{" "}
                  </span>
                </div>
                {customerId === 'New' && 
                  <div className="input-block">
                     <input
                        {...register('company_name')}
                        className={`form-control`}
                        type="text"
                        placeholder="Enter Company name"
                      />
                  </div>
                }
              </div>
              <div className="col-sm-6 col-md-3">
                <div className="input-block">
                  <label>
                    Contact Person
                  </label>

                  <Controller
                    name="contact_name"
                    control={control}
                    render={({ field }) => (
                      <input
                        className={`form-control`}
                        type="text"
                        onChange={field.onChange}
                        value={field.value}
                        autoComplete="true"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-md-3">
                <div className="input-block">
                  <label>
                    Phone Number. <span className="text-danger">*</span>
                  </label>

                  <Controller
                    name="phone_no"
                    control={control}
                    render={({ field }) => (
                      <input
                        className={`form-control ${
                          errors?.phone_no ? "is-invalid" : ""
                        }`}
                        type="text"
                        onChange={field.onChange}
                        value={field.value}
                        autoComplete="true"
                      />
                    )}
                  />

                  <span className="text-danger">
                    {" "}
                    {errors.phone_no?.message}{" "}
                  </span>
                </div>
              </div>
              <div className="col-sm-6 col-md-3">
                <div className="input-block">
                  <label>Email <span className="text-danger">*</span></label>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <input
                        className={`form-control ${
                          errors?.email ? "is-invalid" : ""
                        }`}
                        type="text"
                        onChange={field.onChange}
                        value={field.value}
                        autoComplete="true"
                      />
                    )}
                  />

                  <span className="text-danger">
                    {" "}
                    {errors.email?.message}{" "}
                  </span>
                </div>
              </div>
              <div className="col-sm-6 col-md-3">
                <div className="input-block">
                  <label>Event Address <span className="text-danger">*</span></label>
                  <Controller
                    name="address"
                    control={control}
                    render={({ field }) => (
                      <PlacesAutocomplete
                        hasError={Boolean(errors.address)}
                        formValue={field.value}
                        onAddressSelect={(address) => {
                          getGeocode({ address: address }).then((results) => {
                            const { lat, lng } = getLatLng(results[0]);
                            setValue('address', address);
                            setValue('delivery_coords', {lat, lng});
                          });
                        }}
                      />
                    )}
                  />

                  <span className="text-danger">
                    {" "}
                    {errors.address?.message}{" "}
                  </span>
                  
                </div>
              </div>
              <div className="col-sm-6 col-md-3">
                <div className="input-block">
                  <label>Location Type</label>
                  <Controller
                    name="event_location"
                    control={control}
                    render={({ field }) => (
                      <Select 
                        {...field}
                        placeholder="Select Location Type"
                        options={eventLocations}
                        className="select floating"
                        styles={customStyles}
                        value={eventLocations.find(c => c.value === field.value)}
                        onChange={val => field.onChange(val.value)}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-md-3">
                <div className="input-block">
                  <label>Event Type</label>
                  <Controller
                    name="event_type"
                    control={control}
                    render={({ field }) => (
                      <Select 
                        {...field}
                        placeholder="Select Event Type"
                        options={eventTypes}
                        className="select floating"
                        styles={customStyles}
                        value={eventTypes.find(c => c.value === field.value)}
                        onChange={val => field.onChange(val.value)}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-md-3">
                <div className="row">
                    <div className="input-block col-sm-6 col-md-6">
                      <label>
                        Event Date <span className="text-danger">*</span>
                      </label>

                      <Controller
                        name="startDate"
                        control={control}
                        render={({ field }) => (
                        <DatePicker
                          selected={field.value}
                          onChange={field.onChange}
                          className={`form-control floating datetimepicker ${
                            errors?.startDate ? "is-invalid" : ""
                          }`}
                          type="date"
                          dateFormat="dd-MM-yyyy"
                          // minDate={new Date()}
                        />
                      )}
                    />
                    <span className="text-danger">
                      {" "}
                      {errors.startDate?.message}{" "}
                    </span>
                  </div>
                  
                  <div className="input-block col-sm-6 col-md-6">
                    <label>
                      End Date <span className="text-danger">*</span>
                    </label>

                    <Controller
                      name="endDate"
                      control={control}
                      render={({ field }) => (
                        <DatePicker
                          selected={field.value}
                          onChange={field.onChange}
                          className={`form-control floating datetimepicker ${
                            errors?.endDate ? "is-invalid" : ""
                          }`}
                          type="date"
                          dateFormat="dd-MM-yyyy"
                          // minDate={new Date()}
                        />
                      )}
                    />
                    <span className="text-danger">
                      {" "}
                      {errors.endDate?.message}{" "}
                    </span>
                  </div>
                </div>
              </div>
              
              <div className="col-sm-6 col-md-3">
                <div className="input-block">
                  <label>Diesel</label>
                  <Controller
                    name="supply_diesel"
                    control={control}
                    render={({ field }) => (
                      <input
                        className={`form-control ${
                          errors?.supply_diesel ? "is-invalid" : ""
                        }`}
                        type="text"
                        onChange={field.onChange}
                        value={field.value}
                        autoComplete="true"
                      />
                    )}
                  />
                </div>
              </div>

              <div className="col-sm-6 col-md-3">
                <div className="input-block">
                  <label>Extra Cable</label>
                  <Controller
                    name="extra_cable"
                    control={control}
                    render={({ field }) => (
                      <input
                        className={`form-control ${
                          errors?.extra_cable ? "is-invalid" : ""
                        }`}
                        type="text"
                        onChange={field.onChange}
                        value={field.value}
                        autoComplete="true"
                      />
                    )}
                  />
                </div>
              </div>

              <div className="col-sm-6 col-md-3">
                <div className="input-block">
                  <label>Distribution Box</label>
                  <Controller
                    name="distribution_box"
                    control={control}
                    render={({ field }) => (
                      <input
                        className={`form-control ${
                          errors?.distribution_box ? "is-invalid" : ""
                        }`}
                        type="text"
                        onChange={field.onChange}
                        value={field.value}
                        autoComplete="true"
                      />
                    )}
                  />
                </div>
              </div>

              <div className="col-sm-6 col-md-3">
                <div className="input-block">
                  <label>Change Over</label>
                  <Controller
                    name="change_over"
                    control={control}
                    render={({ field }) => (
                      <input
                        className={`form-control ${
                          errors?.change_over ? "is-invalid" : ""
                        }`}
                        type="text"
                        onChange={field.onChange}
                        value={field.value}
                        autoComplete="true"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div className="table-responsive" style={{minHeight: 200}}>
                  <table
                    className="table table-hover table-white"
                    id="addTable"
                  >
                    <thead>
                      <tr>
                        <th>#</th>
                        <th className="col-sm-2">Generator</th>
                        <th className="col-md-6">Description</th>
                        <th>Capacity Needed</th>
                        <th>Fee</th>
                        <th> </th>
                      </tr>
                    </thead>
                    <tbody className="tbodyone">
                      
                      {rows.map((row, idx) => (
                        <tr key={idx}>
                          <td>{idx + 1}</td>
                          <td>
                            <Select 
                              placeholder="Select Generator"
                              options={generators}
                              className="select floating"
                              styles={customStyles}
                              value={generators.find(c => c.value === row.id)}
                              onChange={({value}) => setGenerator(value, idx)}
                            />
                          </td>
                          <td>
                            <input
                              className="form-control"
                              type="text"
                              value={row.description}
                              onChange={(e) =>
                                setRows((prevRows) =>
                                  prevRows.map((prevRow, i) =>
                                    i === idx
                                      ? { ...prevRow, description: e.target.value }
                                      : prevRow
                                  )
                                )
                              }
                            />
                          </td>
                          <td>
                            <input
                              className="form-control"
                              type="text"
                              value={row.capacity}
                              onChange={(e) =>
                                setRows((prevRows) =>
                                  prevRows.map((prevRow, i) =>
                                    i === idx
                                      ? { ...prevRow, capacity: e.target.value }
                                      : prevRow
                                  )
                                )
                              }
                            />
                          </td>
                          <td>
                            <input
                              className="form-control"
                              type="text"
                              value={row.amount}
                              onChange={(e) =>
                                setRows((prevRows) =>
                                  prevRows.map((prevRow, i) =>
                                    i === idx
                                      ? { ...prevRow, amount: e.target.value }
                                      : prevRow
                                  )
                                )
                              }
                            />
                          </td>
                          
                          <td>
                            {idx === 0 ?
                              <Link
                                to="#"
                                className="text-success font-18"
                                title="Add"
                                id="addProduct"
                                onClick={addRow}
                              >
                                <i className="fa-solid fa-plus" />
                              </Link>
                              :
                              <Link
                                className="text-danger font-18 remove"
                                title="Remove"
                                onClick={() => removeRow(idx)}
                              >
                                <i className="fa-regular fa-trash-can" />
                              </Link>
                            }
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <span className="text-danger">
                  {" "}
                  {errors.generators?.message}{" "}
                </span>
                <div className="row">
                  <div className="col-md-12">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Other Information
                      </label>
                        <Controller
                        name="add_on"
                        control={control}
                        render={({ field }) => (
                          <textarea
                            className="form-control"
                            rows={4}
                            onChange={field.onChange}
                            value={field.value}
                          />
                          )}
                        />
                    </div>
                  </div>
                </div>
              </div>
              {/*
               */}
            </div>

            <div className="submit-section">
              <Link to="/bookings" className="btn submit-btn m-r-10">
                Cancel
              </Link>
              <button
                className="btn btn-primary submit-btn"
                aria-label="Close"
                type="submit"
              >
                {isLoading ?
                  <>
                    <span
                      className="spinner-border spinner-border-sm me-1"
                      role="status"
                      aria-hidden="true"
                    />Submitting...
                  </>
                  :
                    'Submit' 
                  }
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddEditBooking;
