import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Table } from "antd";
import {
  Avatar_06,
} from "../../../Routes/ImagePath";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { useLazyGetBookingsQuery } from "../../../services/customer.service";
import { useDispatch } from "react-redux";
import { CUSTOMERS } from "../../../services/constants";
import { toggleDelete } from "../../../redux/slices/ui.slice";
import dayjs from "dayjs";
import TicketModelPopup from "../../../components/modelpopup/TicketModelPopup";

const CustomerBookings = () => {

  const [getBookings, { data, isLoading, error }] = useLazyGetBookingsQuery()
  let [searchParams, setSearchParams] = useSearchParams();

  const [booking, setBooking] = useState(null);
  const dispatch = useDispatch();

  const id = searchParams.get('id');

  useEffect(() => {
    getBookings(1)
  }, [])

  useEffect(() => {
    if (id && data) {
      document.getElementById(`booking-${id}`).click();
    }
  }, [id, data]);

  const doDelete = (e, item) => {
    // e.currentTarget.closest('tr').remove()
    dispatch(
      toggleDelete({
        open: true,
        title: 'Customer Bookings',
        url: `${CUSTOMERS}/bookings/${item.id}/delete`,
        ele: e.currentTarget,
        parent: 'tr'
      })
    );
  }

  const columns = [
    {
      title: "",
      render: (text, record) => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#view_customer_booking"
              onClick={() => setBooking(record)}
            >
              <i className="fa fa-pencil m-r-5" /> View Details
            </Link>
            <Link
              className="dropdown-item"
              to={`/new-booking?customer_booking=${record.id}`}
            >
              <i className="fa fa-pencil m-r-5" /> Move to Booking
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              onClick={(e) => doDelete(e, record)}
            >
              <i className="fa fa-trash m-r-5" /> Delete
            </Link>
          </div>
        </div>
      ),
      sorter: true,
    },
    {
      title: "Company Name",
      dataIndex: "company_name",
      render: (text, record) => (
        <span className="table-avatar">
          <Link to="#" className="avatar">
            <img alt="" src={Avatar_06} />
          </Link>
          <Link to="#">{text}</Link>
        </span>
      ),
      sorter: (a, b) => a.company_name.length - b.company_name.length,
    },
    {
      title: "Contact Person",
      dataIndex: "contact_person",
      sorter: (a, b) => a.contact_person.length - b.contact_person.length,
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.Email.length - b.Email.length,
    },

    {
      title: "Contact No.",
      dataIndex: "contact_number",
      sorter: (a, b) => a.contact_number.length - b.contact_number.length,
    },
    {
      title: "Type ",
      dataIndex: "form_type",
      render: (text) => (
        text === 'residential' ? 'RESIDENTIAL/CORPORATE USE' : 'EVENTS'
      )
    },
    {
      title: "Address",
      dataIndex: "address",
      sorter: (a, b) => a.address.length - b.address.length,
    },
    {
      title: "Event Date",
      dataIndex: "event_date",
      sorter: (a, b) => a.event_date.length - b.event_date.length,
      render: (text, r) => (
        <div>
          {dayjs(r.startDate).format('DD MMM YYYY')}
          {" "}
          {r.startDate !== r.endDate && <>
          - {dayjs(r.endDate).format('DD MMM YYYY')}</>}
        </div>
      )
    },
    {
      title: "Gen Cap",
      dataIndex: "gen_capacity",
      sorter: (a, b) => a.gen_capacity.length - b.gen_capacity.length,
      render: (t) => (t + 'kva')
    }, 
    {
      title: "DB or C/O ",
      dataIndex: "co_db",
    },
    {
      title: "Action",
      render: (text, record) => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <Link
              className="dropdown-item"
              to="#"
              id={`booking-${record.id}`}
              data-bs-toggle="modal"
              data-bs-target="#view_customer_booking"
              onClick={() => setBooking(record)}
            >
              <i className="fa fa-pencil m-r-5" /> View Details
            </Link>
            <Link
              className="dropdown-item"
              to={`/new-booking?customer_booking=${record.id}`}
            >
              <i className="fa fa-pencil m-r-5" /> Move to Booking
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              onClick={(e) => doDelete(e, record)}
            >
              <i className="fa fa-trash m-r-5" /> Delete
            </Link>
          </div>
        </div>
      ),
      sorter: true,
    },
  ];
  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs
            maintitle="Customer Bookings"
            title="Dashboard"
            subtitle="Customer Bookings"
          />
          {/* /Page Header */}
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                {/* <SearchBox /> */}
                <Table
                  className="table-striped"
                  style={{ overflowX: "auto" }}
                  columns={columns}
                  dataSource={data?.data}
                  rowKey={(record) => record.id}
                  loading={isLoading}
                  pagination={{ 
                    defaultPageSize: 100, 
                    total: data?.count,
                    // showSizeChanger: true, 
                    // pageSizeOptions: ['10', '20', '30', '50', '100']
                    onChange: (page, pageSize) => {
                      getBookings(page);
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <TicketModelPopup data={booking} onClose={() => setBooking(null)} />
    </>
  );
};

export default CustomerBookings;
