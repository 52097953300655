import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Applogo } from "../../../Routes/ImagePath";

const BookingFormType = () => {
  const navigate = useNavigate();

  return (
    <div className="account-page">
      <div className="main-wrapper">
        
        <div className="account-content">
          <div className="container">
            <div className="account-logo">
              <Link to="/app/main/dashboard">
                <img src={Applogo} alt="Dreamguy's Technologies" />
              </Link>
            </div>
            <div className="account-box booking-type">
              <div className="account-wrapper">
                <h3 className="account-title">PPS Booking Form</h3>
                <p className="account-subtitle">
                  Please select your event type below
                </p>
                <div className="row">

                  <div class="col-sm-12 col-md-6">
                    <div class="card" onClick={() => navigate('/booking-form/residential')} style={{height: '120px'}}>
                        <span class="ribbone-info-left">
                          <span><i data-feather="zap" class="feather-zap"></i></span>
                        </span>
                        <div class="card-body  p-6">
                          <h6 class="card-subtitle mb-2 text-dark fw-bold text-end">Residential/Corporate</h6>
                          <p class="card-text">Click here if you need power rental for business or residential purpose.</p>
                        </div>
                      </div>                 
                    </div>
                  <div class="col-sm-12 col-md-6">
                    <div class="card" onClick={() => navigate('/booking-form/events')} style={{height: '120px'}}>
                      <span class="ribbone-success-left">
                        <span><i data-feather="zap" class="feather-zap"></i></span>
                      </span>
                      <div class="card-body  p-6">
                        <h6 class="card-subtitle mb-2 text-dark fw-bold text-end">Events</h6>
                        <p class="card-text">Click here if you need power rental for event purpose.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BookingFormType;
