import { createSlice } from "@reduxjs/toolkit";


export const userSlice = createSlice({
  name: "user",
  initialState: { 
    user: null,
    token: null,
    isAuthenticated: false,
  },
  reducers: {
    updateUser: (state, { payload }) => {
      const userObj = payload;
      const newState = { ...state };
      Object.assign(newState, userObj);
      return newState;
    },
    logoutUser: (state) => {
      const newState = { ...state };
      newState.user = null;
      newState.token = null;
      newState.isAuthenticated = false;
      return newState;
    },
    setLayout: (state, { payload }) => {
      state.layout = payload;
    },
  },
});

export const { updateUser, logoutUser, setLayout } = userSlice.actions;
export default userSlice.reducer;
