import React, { useState } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import dayjs from "dayjs";

const BookingReportModal = ({report}) => {
  const [value, setValue] = useState(25);
  
  return (
    <>
      {/* Add Goal Modal */}
      <div id="report_modal" className="modal custom-modal fade" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Report as @ {dayjs.unix(report?.start_time/1000).format("hh:mm a")} to {dayjs.unix(report?.end_time/1000).format("hh:mm a")}</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
            <div className="row mb-2">
                <div className="col-md-6">
                  <b>Diesel Consumed</b>
                </div>
                <div className="col-md-6">
                  <b>Generator</b>
                </div>
                <div className="col-md-6">{report?.diesel} Liters</div>
                <div className="col-md-6">{report?.generator?.name} ({report?.generator?.size} kva)</div>
              </div>
              <div className="row my-2">
                <div className="col-md-12">
                  <b>AMP Readings</b>
                </div>
                <div className="col-md-4">{report?.amp_reading_1} amps</div>
                <div className="col-md-4">{report?.amp_reading_2} amps</div>
                <div className="col-md-4">{report?.amp_reading_3} amps</div>
               
              </div>
              <div className="row">
                <div className="col-md-12">
                  <b>Voltag Reading</b>
                </div>
                <div className="col-md-4">{report?.voltage_line_1}</div>
                <div className="col-md-4">{report?.voltage_line_2}</div>
                <div className="col-md-4">{report?.voltage_line_3}</div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
      {/* /Add Goal Modal */}
    </>
  );
};

export default BookingReportModal;
