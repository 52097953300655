import apiSlice from "./api/api";
import {
  CUSTOMERS,
  CUSTOMERS_ALL,
  GET_CUSTOMER,
  GET_CUSTOMERS_BOOKINGS,
  LEADS,
  SAVE_CUSTOMER_BOOKING,
  SAVE_LEAD_COMMENT,
} from "./constants";

const customerApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // get customers
    getCustomers: builder.query({
      query: ({search, page}) => ({
        url: `${CUSTOMERS}?page=${page}&search=${search}`,
        method: "GET",
      }),
      providesTags: ["Customers"],
    }),
    // list all customers
    getAllCustomers: builder.query({
      query: () => ({
        url: CUSTOMERS_ALL,
        method: "GET",
      }),
      providesTags: ["Customers"],
    }),
    // save customer
    saveCustomer: builder.mutation({
      query: (data) => ({
        url: CUSTOMERS,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["Customers"]
    }),
    // update custoemr
    updateCustomerStatus: builder.mutation({
      query: (data) => ({
        url: `${CUSTOMERS}/${data.id}`,
        body: data,
        method: "PATCH",
      }),
      invalidatesTags: ["Customers"]
    }),
    // get leads
    getLeads: builder.query({
      query: (page) => ({
        url: `${LEADS}?page=${page}`,
        method: "GET",
      }),
      providesTags: ["Leads"],
    }),
    // save lead
    saveLead: builder.mutation({
      query: (data) => ({
        url: LEADS,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["Leads"]
    }),
    // update lead
    updateLead: builder.mutation({
      query: (data) => ({
        url: `${LEADS}/${data.id}`,
        body: data,
        method: "PATCH",
      }),
      invalidatesTags: ["Leads"]
    }),
    // save comment
    saveComment: builder.mutation({
      query: (data) => ({
        url: `${SAVE_LEAD_COMMENT}`,
        method: "POST",
        body: data
      }),
      invalidatesTags: ["Bookings"]
    }),
    // save comment
    saveBooking: builder.mutation({
      query: (data) => ({
        url: `${SAVE_CUSTOMER_BOOKING}`,
        method: "POST",
        body: data
      }),
      invalidatesTags: ["CustomerBookings"]
    }),
    getBookings: builder.query({
      query: (page) => ({
        url: `${GET_CUSTOMERS_BOOKINGS}?page=${page}`,
        method: "GET",
      }),
      providesTags: ["CustomerBookings"],
    }),
    getCustomerBooking: builder.query({
      query: (id) => ({
        url: `${GET_CUSTOMERS_BOOKINGS}/${id}`,
        method: "GET",
      }),
      providesTags: ["CustomerBookings"],
    }),

    getCustomer: builder.query({
      query: (id) => ({
        url: `${GET_CUSTOMER}/${id}`,
        method: "GET",
      }),
      providesTags: ["Customers"],
    }),  }),
});

export const {
  useLazyGetCustomersQuery,
  useGetAllCustomersQuery,
  useSaveCustomerMutation,
  useUpdateCustomerStatusMutation,
  useLazyGetLeadsQuery,
  useSaveLeadMutation,
  useUpdateLeadMutation,
  useSaveCommentMutation,
  useSaveBookingMutation,
  useLazyGetBookingsQuery,
  useGetCustomerQuery,
  useGetCustomerBookingQuery
} = customerApiSlice;
