import apiSlice from "./api/api";
import {
  CREATE_INVOICE,
  GET_INVOICES,
  GET_INVOICE,
  UPDATE_INVOICE,
  UPDATE_INVOICE_STATUS,
  GET_PAYMENTS,
  RECORD_PAYMENTS,
  SEND_INVOICE
} from "./constants";

const invoiceApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({

    //get all invoices
    getInvoices: builder.mutation({
      query: (param) => ({
        url: `${GET_INVOICES}`,
        method: "POST",
        body: param
      }),
      providesTags: [""],
    }),

    //get single invoice
    getInvoice: builder.query({
      query: ({invoiceId}) => ({
        url: `${GET_INVOICE}/${invoiceId}`,
        method: "GET",
      }),
      providesTags: [""],
    }),
    // generate invoice
    createInvoice: builder.mutation({
      query: (data) => ({
        url: CREATE_INVOICE,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["Bookings"]
    }),
    // update invoice
    updateInvoice: builder.mutation({
      query: (data) => ({
        url: `${UPDATE_INVOICE}`,
        body: data,
        method: "PATCH",
      }),
      invalidatesTags: ["Invoices"]
    }),
    // update invoice status
    updateInvoiceStatus: builder.mutation({
      query: (data) => ({
        url: `${UPDATE_INVOICE_STATUS}`,
        body: data,
        method: "PATCH",
      }),
      invalidatesTags: ["Invoices"]
    }),
    //get all payments
    getPayments: builder.mutation({
      query: (param) => ({
        url: `${GET_PAYMENTS}`,
        method: "POST",
        body: param
      }),
      providesTags: [""],
    }),
    //record payment
    recordPayment: builder.mutation({
      query: (param) => ({
        url: `${RECORD_PAYMENTS}`,
        method: "POST",
        body: param
      }),
      providesTags: ["Invoices"],
    }),
    //send invoice
    emailInvoice: builder.query({
      query: (invoiceId) => ({
        url: `${SEND_INVOICE}/${invoiceId}?type=email`,
        method: "GET",
      }),
      providesTags: [""],
    }),
  }),
});

export const {
  useGetInvoiceQuery,
  useGetInvoicesMutation,
  useCreateInvoiceMutation,
  useUpdateInvoiceMutation,
  useUpdateInvoiceStatusMutation,
  useGetPaymentsMutation,
  useRecordPaymentMutation,
  useLazyEmailInvoiceQuery
} = invoiceApiSlice;
