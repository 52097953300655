import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import { useSaveGenMutation } from "../../../services/settings.service";
import alertify from "alertifyjs";
import { GeneratorSchema } from "../../../utils/validations";

const GeneratorModal = ({ data, onClose }) => {
  const [saveGen, { isLoading, isSuccess, isError }] = useSaveGenMutation();

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(GeneratorSchema),
    mode: 'onBlur'
  });

  useEffect(() => {
    if(data) {
      reset(data);
    } else {
      resetForm();
    }
  }, [data]);

  const onSubmit = formData => { 
    const payload = {
      id: formData.id,
      name: formData.name,
      size: parseInt(formData.size)
    }
    saveGen(payload).then(res => {
      const {success} = res.data;
      if (success && !data) {
        resetForm()
        alertify.success("Saved successfully");
      }else if (success) {
        alertify.success("Saved successfully");
      } else {
       alertify.error("Something went wrong. Unable to save generator");
      }
    }).catch(e => console.log(e.message) );

    if(isError) {
      alertify.error("Something went wrong. Unable to save user");
    }
  }

  const resetForm = () => {
    reset({
      name: "",
      size: "",
      units: "",
      id: "",
    })
  }

  return (
    <div id="gen_form" className="modal custom-modal fade" role="dialog">
      <div
        className="modal-dialog modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{data ? 'Edit' : 'Add'} Generator</h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                resetForm();
                onClose()
              }}
              data-bs-dismiss="modal"
              aria-label="Close"
              id="close-form-modal"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-sm-12">
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      Name <span className="text-danger">*</span>
                    </label>
                    <Controller
                      name="name"
                      control={control}
                      render={({ field }) => (
                        <input
                          className={`form-control ${
                            errors?.name ? "is-invalid" : ""
                          }`}
                          type="text"
                          onChange={field.onChange}
                          value={field.value}
                          autoComplete="true"
                        />
                      )}
                    />

                    <span className="text-danger">
                      {" "}
                      {errors.name?.message}{" "}
                    </span>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      Size (in kva)<span className="text-danger">*</span>
                    </label>
                    <Controller
                      name="size"
                      control={control}
                      render={({ field }) => (
                        <input
                          className={`form-control ${
                            errors?.size ? "is-invalid" : ""
                          }`}
                          type="number"
                          onChange={field.onChange}
                          value={field.value}
                        />
                      )}
                    />
                    <span className="text-danger">
                      {" "}
                      {errors.size?.message}{" "}
                    </span>
                  </div>
                </div>
                {/* <div className="col-sm-12">
                  <div className="input-block mb-3">
                    <label className="col-form-label">Available Units  <span className="text-danger">*</span></label>
                    <Controller
                      name="units"
                      control={control}
                      render={({ field }) => (
                        <input
                          className={`form-control ${
                            errors?.units ? "is-invalid" : ""
                          }`}
                          type="text"
                          onChange={field.onChange}
                          value={field.value}
                          autoComplete="true"
                        />
                      )}
                    />

                    <span className="text-danger">
                      {" "}
                      {errors.units?.message}{" "}
                    </span>
                  </div>
                </div> */}
                
              </div>
              
              <div className="submit-section">
                <button
                  className="btn btn-primary submit-btn"
                  // data-bs-dismiss="modal"
                  // aria-label="Close"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ?
                  <>
                    <span
                      className="spinner-border spinner-border-sm me-1"
                      role="status"
                      aria-hidden="true"
                    />Submitting...
                  </>
                  :
                    'Submit' 
                  }
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneratorModal;
