import usePlacesAutocomplete from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import styles from './styles.module.css';

const PlacesAutocomplete = ({onAddressSelect, hasError, formValue}) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    // callbackName: "pps",
    requestOptions: { componentRestrictions: { country: 'ng' } },
    debounce: 300,
    cache: 86400,
  });

  const ref = useOnclickOutside(() => {
    // When the user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  const renderSuggestions = () => {
    return data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
        description,
      } = suggestion;

      return (
        <li
          key={place_id}
          onClick={() => {
            setValue(description, false);
            clearSuggestions();
            onAddressSelect && onAddressSelect(description);
          }}
        >
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });
  };

  return (
    <div className={styles.autocompleteWrapper} ref={ref}>
        <input
            className={`form-control ${hasError ? "is-invalid" : ""}`}
            type="text"
            onChange={(e) => setValue(e.target.value)}
            value={value || formValue}
            autoComplete="true"
            disabled={!ready}
        />

        {status === 'OK' && (
            <ul className={styles.suggestionWrapper}>{renderSuggestions()}</ul>
        )}
    </div>
  );
};

export default PlacesAutocomplete;