import React, { useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Pie } from "react-chartjs-2";
import { useGetSalesSummaryQuery } from "../../../../../services/dashbaord.service";

// import "../../../../../assets/css/index.css";

// const barchartdata = [
//   { y: "Jan", "Total Bookings": 100, "Total Cancelled": 50 },
//   { y: "Feb", "Total Bookings": 75, "Total Cancelled": 3},
//   { y: "Mar", "Total Bookings": 0,  },
//   { y: "Apr", "Total Bookings": 0 },
//   { y: "May", "Total Bookings": 0, },
//   { y: "Jun", "Total Bookings": 0, },
//   { y: "Jul", "Total Bookings": 0, },
//   { y: "Aug", "Total Bookings": 0 },
//   { y: "Sept", "Total Bookings": 0 },
//   { y: "Oct", "Total Bookings": 0 },
//   { y: "Nov", "Total Bookings": 0 },
//   { y: "Dec", "Total Bookings": 0 },
// ];

const linechartdata = [
  { y: "2006", "Total Sales": 50, "Total Revenue": 90 },
  { y: "2007", "Total Sales": 75, "Total Revenue": 65 },
  { y: "2008", "Total Sales": 50, "Total Revenue": 40 },
  { y: "2009", "Total Sales": 75, "Total Revenue": 65 },
  { y: "2010", "Total Sales": 50, "Total Revenue": 40 },
  { y: "2011", "Total Sales": 75, "Total Revenue": 65 },
  { y: "2012", "Total Sales": 100, "Total Revenue": 50 },
];

const option9 = {
  maintainAspectRatio: false,
  responsive: true,
  legend: {
    display: true,
  },
  animation: {
    animateScale: true,
    animateRotate: true,
  },
};

const Charts = () => {
  const {data} = useGetSalesSummaryQuery();
  const [pieData, setPieData] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [
          "#664dc9",
          "#44c4fa",
          "#38cb89",
          "#3e80eb",
          "#ffab00",
          "#ef4b4b",
        ],
      },
    ],
  })

  useEffect(() => {
    if (data) {
      const datasets = [
        {
          data: data.counts,
          backgroundColor: [
            "#664dc9",
            "#44c4fa",
            "#38cb89",
            "#3e80eb",
            "#ffab00",
            "#ef4b4b",
          ],
        },
      ];

      setPieData({
        ...pieData, 
        labels: data.labels,
        datasets, 
      })
    }
  }, [data]);
  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-6 text-center">
              <div className="card">
                <div className="card-body">
                  <h3 className="card-title">Current Month Bookings</h3>
                  <ResponsiveContainer width="100%" height={300}>
                    <BarChart
                      data={data?.barchartData}
                      margin={{
                        top: 5,
                        right: 5,
                        left: 5,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid />
                      <XAxis dataKey="y" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="Total Bookings" fill="#00443f" />
                      <Bar dataKey="Total Cancelled" fill="#fc6075" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
            <div className="col-md-6 text-center">
              <div className="card">
                <div className="card-body">
                  <h3 className="card-title">Bookings by Generator</h3>
                  <ResponsiveContainer width="100%" height={300}>
                    <Pie className="h-300" data={pieData} options={option9} />

                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Charts;
